<template>
  <div class="sanity-box">

    <Card style="width: 30em" v-if="isInitialSelection">
      <template #title>{{ $t("sanityBox.choose") }}</template>
      <template #subtitle>{{ $t("sanityBox.chooseItems") }}</template>
      <template #content>
        {{ $t("sanityBox.description") }}
      </template>
      <template #footer>
          <div class="flex pt-4 justify-content-between">
            <Button icon="pi pi-box" style="width: 10em;" :label="$t('sanityBox.predefinedBox.buttonLabel')"
              :outlined='sanitybox.userSelection != "predefined"' @click="setBoxTypeSelection('predefined')" />
            <Button icon="pi pi-inbox" style="width: 10em; margin-left: 5px;"
              :label="$t('sanityBox.customBox.buttonLabel')" :outlined='sanitybox.userSelection != "custom"' @click="setBoxTypeSelection('custom')" />
          </div>
      </template>
    </Card>
    <div class="p-card" style="width: 30em; padding: 10px;" v-else>
      <div style="vertical-align: auto; display: inline; font-weight: bold; font-size: 1.5rem; top: -5px; position: relative;">{{ $t("sanityBox.choose")
      }}</div>
      <div class="flex pt-4 justify-content-between">
        <Button icon="pi pi-box" style="width: 10em;" :label="$t('sanityBox.predefinedBox.buttonLabel')"
          :outlined='sanitybox.userSelection != "predefined"' @click="setBoxTypeSelection('predefined')" />
        <Button icon="pi pi-inbox" style="width: 10em; margin-left: 5px;" :label="$t('sanityBox.customBox.buttonLabel')"
          :outlined='sanitybox.userSelection != "custom"' @click="setBoxTypeSelection('custom')" />
      </div>
    </div>
    <br />
    <div class="itemBoxAndDescription">
      <Card class="sanity-item-card" style="width: 30em; margin-right: 15px;" v-if="describeItem.itemName">
        <template #title>{{ $t("boxselection.product.description") }}</template>
        <template #content>
          <h3>
            {{ describeItem.itemName }}
          </h3>
          <div v-if="describeItem.itemImageUrl" style="display:inline-flex">

            <Image alt="Produkt bild" preview>
              <template #indicatoricon>
                <i class="pi pi-search-plus"></i>
              </template>
              <template #image>
                <img :src="describeItem.itemImageUrl" alt="product image"
                  style="max-width: 15em; max-height: 10em; display:flex" />
              </template>
              <template #preview="slotProps">
                <img :src="describeItem.itemImageUrl" alt="preview" :style="slotProps.style" @click="slotProps.onClick" />
              </template>
            </Image>
            <div style="margin-left: 10px;">{{ describeItem.itemDescription }}</div>
          </div>
          <div v-else>
            <div v-if="describeItem.itemDescription">{{ describeItem.itemDescription }}</div>
            <div v-else style="color: gray;">{{ $t("boxselection.nodescription.hint") }}</div>
          </div>
        </template>
      </Card>
      <div v-if='sanitybox.userSelection == "predefined"'>
        <template v-for="defConfig in sanitybox.predefinedSanityBoxes">
          <Card style="width: 30em; margin-bottom: 30px;" class="sanity-item-card" v-if="defConfig.productActive">
            <template #title>{{ defConfig.productName }} </template>
            <template #subtitle v-if="sanitybox.selectedPredefinition == defConfig && variantLessProducts.length > 0">
              <div style="color: red">{{ $t("sanityBox.customBox.warningNotChoosedVariant") }}<div
                  v-for="product in variantLessProducts">{{ product.itemName }}</div>
              </div>
            </template>
            <template #content>
              <div v-for="content in defConfig.productItemList">
                <div :style="cursorStyle" @click="showItemDescription($event, content)"
                  :class="['itemEntry', { 'selected': content === describeItem || content.item_id === describeItem.item_id }]">
                  {{ content.quantity }} x {{
                    content.itemName }}</div> <template
                  v-if="content.hasGroup && sanitybox.selectedPredefinition == defConfig"><template
                    v-for="itemGroupList in content.itemGroupList">
                    <div style="display: inline; color: red" v-if="itemGroupList.selectedVariant == null">{{
                      itemGroupList.groupName }}:</div>
                    <div v-else style="display: inline; ">{{ itemGroupList.groupName }}:</div> <template
                      v-for="variant in itemGroupList.groupVariantList">
                      <Button :label="variant.variantValue"
                        style="padding: 7px; margin-left: 3px; width: auto; height: 20px;"
                        :outlined="itemGroupList.selectedVariant != variant"
                        @click="selectItemVariant(variant, itemGroupList, defConfig)"></Button></template>
                  </template></template>
              </div>
              <Button style="margin-top: 5px;" :outlined="sanitybox.selectedPredefinition != defConfig"
                @click="selectPredefinedBox(defConfig)"
                :label="(sanitybox.selectedPredefinition == defConfig) ? $t('sanityBox.predefinedBox.choose') : $t('sanityBox.predefinedBox.choosen')" />
            </template>
          </Card>
        </template>
      </div>
      <div class="sanity-items" v-if='sanitybox.userSelection == "custom"'>
        <Card style="width: 30em;" class="sanity-item-card">
          <template #title>
            {{ $t("sanityBox.customBox.configurableBox") }}
          </template>
          <template #content>
            <template v-for="sanityItem in sanityItems" :key="sanityItem.itemName"
              style="border-bottom: 2px solid; padding: 5px;">
              <div v-if="isVisible(sanityItem)" class="p-card itemcard" :style="cursorStyle"
                @click="showItemDescription($event, sanityItem)">
                <div v-if="sanityItem === describeItem || sanityItem.item_id === describeItem.item_id"
                  style="font-weight: bold;">{{ sanityItem.itemName }}</div>
                <div v-else>{{ sanityItem.itemName }}</div>
                <div class="variants" v-if="sanityItem.hasGroup">
                  <template v-for="itemGroup in sanityItem.itemGroupList">
                    <div style="top: -5px; position: relative; display: inline; font-weight: bold; color:gray;">
                      {{ itemGroup.groupName }}:
                    </div>
                    <template v-for="variant in itemGroup.groupVariantList">
                      <Button style="padding: 7px; margin-left: 3px; width: auto; height: 20px;"
                        v-model="sanityItem.selectedVariant" :value="variant" :outlined="!variant.selected"
                        @click="selectVariant(sanityItem, variant)" :label="variant.variantValue"
                        :disabled="!canClickVariant(variant) && !variant.selected" />
                    </template>
                  </template>
                  <br /> <br />
                  <div v-if="sanityItem.itemGroupList[0].selectedVariant != null">
                    <Button icon="pi pi-minus" class="adjustAmountButtons" @click="removesanityItem(sanityItem)"
                      :disabled="sanityItem.quantity == 0" />
                    <span class="quantity">{{ sanityItem.quantity }}</span>
                    <Button icon="pi pi-plus" class="adjustAmountButtons" @click="addsanityItem(sanityItem)"
                      :disabled="canClickPlus(sanityItem)" />
                  </div>
                </div>
                <div v-else class="controls">
                  <Button icon="pi pi-minus" class="adjustAmountButtons" @click="removesanityItem(sanityItem)"
                    :disabled="sanityItem.quantity == 0" />
                  <span class="quantity">{{ sanityItem.quantity }}</span>
                  <Button icon="pi pi-plus" class="adjustAmountButtons" @click="addsanityItem(sanityItem)"
                    :disabled="canClickPlus(sanityItem)" />
                </div>
              </div>
            </template>
          </template>
        </Card>
      </div>

    </div>
    <Toast position="top-right" group="bc" @close="shoppingCartClosed">
      <template #message="slotProps">
        <div style="width: 100%">
          <h3><i class="pi pi-shopping-cart" style="margin-left: 0.5rem; font-weight: bold;"></i> {{
            $t("sanityBox.shoppingCart.toasterTitle") }}</h3>
          <div v-if="warenkorb.boxtype == 'predefined'">
            <template v-if="warenkorb.selectedBox">
              <div v-for="itemEntry in warenkorb.selectedBox?.productItemList">
                <span style="font-weight: bold;"> {{ itemEntry.quantity }} </span> x {{ itemEntry.itemName }} <template
                  v-if="itemEntry.hasGroup"><template v-if="itemEntry.itemGroupList[0]?.selectedVariant"><span
                      style="font-weight: bold;">({{
                        itemEntry.itemGroupList[0].selectedVariant.variantValue
                      }})</span></template><template v-else><span style="color: red">{{
  itemEntry.itemGroupList[0].groupName }}</span></template></template>
              </div>
            </template>
            <template v-else>
              <span style="color: red">{{ $t("sanityBox.validate.error.selectPackage") }}</span>
            </template>
          </div>
          <div v-else-if="warenkorb.boxtype == 'custom'">
            <ProgressBar class="pbFuellstand" :value="(total / 40) * 100"
              :class="{ 'green': ((total / 40) * 100) >= 90 }">
              $t("sanityBox.validate.error.fillProgressBar")</ProgressBar>

            <div style="width: 100%" v-for="item in warenkorb.selectedSanityItems">
              <span style="font-weight: bold;"> {{ item.quantity }} </span> x {{ item.itemName }} <span
                style="font-weight: bold;" v-if="item.hasGroup && item.itemGroupList[0]?.selectedVariant">({{
                  item.itemGroupList[0].selectedVariant.variantValue
                }})</span>
            </div>
          </div>
          <div v-else>
            <span style="color: red">{{ $t("sanityBox.validate.error.selectPackage") }}</span>
          </div>
        </div>
      </template>
    </Toast>
    <div v-if="sanitybox.userSelection" style="display: flex; justify-content: flex-end;"
      class="p-toast p-component p-toast-top-right">
      <Button icon="pi pi-shopping-cart" text raised rounded aria-label="Filter" @click="showToast"
        :pt="{ root: { style: [warenkorbGreen ? 'background-color: lightgreen;' : ''] } }" />
    </div>

  </div>
</template>


<script setup>
import { reactive, computed, onMounted, onBeforeUpdate, watch, inject, ref } from 'vue';
import { router } from '../router';
import { useToast } from "primevue/usetoast";
import SlugComponent from '@/api/slug.js';
import Toast from 'primevue/toast';
import iAxios from '../api/axiosInterface';
import { useI18n } from "vue-i18n";
import { localStore } from "@/store/store";
const cursorStyle = ref({
  cursor: 'pointer'
});

const store = localStore();
let response = reactive({});

const boxValidate = inject("boxValidate");
//const whoAmIResponse = inject("companyData");
let whoAmIResponse = store.companyData;
const warenkorb = store.warenkorb;

const sanitybox = reactive({});
const sanityItems = ref([]);
const variantLessProducts = reactive([]);
const toast = useToast();
const { t } = useI18n();
let shoppingCartVisible = false;

const warenkorbGreen = reactive({});
const describeItem = ref({});
const isInitialSelection = ref(true);

onBeforeUpdate(() => {
  whoAmIResponse = store.companyData;
}),

  boxValidate.validate = () => {
    let validationViolation = [];

    validationRules.forEach(rule => {
      let ruleRes = rule();
      if (!ruleRes) {
        return;
      }
      validationViolation.push(ruleRes);
    });
    return validationViolation;
  }

const validationRules = [
  () => {
    return (!warenkorb.boxtype) ? t("sanityBox.validate.error.selectPackage") : null;
  },
  () => {
    return (warenkorb.boxtype === "custom" && total.value < 36) ? t("sanityBox.validate.error.fillProgressBar") : null;
  },
  () => {
    if (warenkorb.boxtype !== "predefined") {
      return null;
    }
    if (!warenkorb.selectedBox) {
      return t("sanityBox.validate.error.selectPackage");
    }
    let prefix = t("sanityBox.validate.error.selectVariant");
    let postFix = "";
    for (let si in warenkorb.selectedBox.productItemList) {
      let item = warenkorb.selectedBox.productItemList[si];
      if (!item.hasGroup) {
        continue;
      }
      if (!item.itemGroupList[0].selectedVariant) {
        postFix += item.itemGroupList[0].groupName + " ";
      }
    }
    if (!postFix) {
      return null;
    }
    return prefix + postFix;
  }
]

const shoppingCartClosed = () => {
  shoppingCartVisible = false;
}

const showToast = () => {
  if (shoppingCartVisible) {
    return;
  }
  toast.removeAllGroups();
  toast.add({ group: "bc", severity: 'info', summary: 'Sticky Message', icon: 'pi-shopping-cart' });
  shoppingCartVisible = true;
}

watch([sanitybox, sanityItems], () => {
  warenkorb.boxtype = sanitybox.userSelection;

  if (warenkorb.boxtype == 'predefined') {
    warenkorb.selectedBox = sanitybox.selectedPredefinition
  } else {
    warenkorb.selectedSanityItems = [];
    sanityItems.value.forEach(item => {
      if (item.quantity > 0) {
        if (item.hasGroup) {
          item.selectedVariant = item.itemGroupList[0].selectedVariant;
        }
        warenkorb.selectedSanityItems.push(item);
      }
    });
  }
  warenkorbGreen.value = validationRules.find(o => o() !== null) === undefined;
}, { deep: true });
console.log("🚀 ~ file: BoxConfig.vue:291 ~ watch ~ warenkorb:", warenkorb)

const hasToChooseAnyVariant = (predefinedSanitybox) => {
  for (const key in predefinedSanitybox.productItemList) {
    let productItem = predefinedSanitybox.productItemList.at(key);

    let index = variantLessProducts.indexOf(productItem);
    if (index != -1) {
      variantLessProducts.splice(index);
    }

    if (productItem.hasGroup) {
      for (let key in productItem.itemGroupList) {
        let itemGroup = productItem.itemGroupList.at(key);
        if (!itemGroup.selectedVariant) {
          variantLessProducts.push(productItem);
        }
      }
    }
  }
  return variantLessProducts.length > 0;
}

onMounted(async () => {
  const slug = new SlugComponent(router, SlugComponent);
  const slugValue = slug.getSlug();

  try {
    let url = process.env.VUE_APP_BASE_API_URL_PATIENT + '/itemlist/' + whoAmIResponse.companyId;

    response = await iAxios.get(url);

    sanitybox.predefinedSanityBoxes = whoAmIResponse.productList;
    console.log("🚀 ~ file: BoxConfig.vue:318 ~ onMounted ~ sanitybox.predefinedSanityBoxes:", sanitybox.predefinedSanityBoxes)
    sanitybox.predefinedSanityBoxes.forEach(box => {
      box.productItemList.forEach(itemEntry => {
        if (!itemEntry.hasGroup) {
          return;
        }
        itemEntry.itemGroupList.forEach(itemGroup => {
          delete itemGroup.selectedVariant;
        })
      });
    })

    sanityItems.value = response.data.itemList;

    sanityItems.value.forEach(element => {
      element.quantity = 0;
      element.selectedVariant = null;
      element.itemGroupList.forEach(itemGroup => {
        itemGroup.groupVariantList.forEach(groupVariant => {
          groupVariant.selected = false;

        })
        delete itemGroup.selectedVariant;
      });
    });
    console.log(sanitybox.predefinedSanityBoxes);

    if (response.status !== 200) {
      console.error('Fehler beim Senden der Daten an Backend');
    }
  } catch (err) {
    console.error('Fehler beim Senden der Daten:', err);
  }
});

const showItemDescription = (event, item) => {
  if (event.target.classList.contains('p-button-icon') || event.target.classList.contains('p-button-label')) {
    return;
  }
  describeItem.value = item;
}

const selectItemVariant = (variant, itemGroupList, predefSanitybox) => {
  itemGroupList.selectedVariant = variant;

  hasToChooseAnyVariant(predefSanitybox);

  showToast();
}

const selectPredefinedBox = (predefSanitybox) => {
  showToast();

  sanitybox.selectedPredefinition = predefSanitybox;

  if (variantLessProducts.length > 0) {
    variantLessProducts.splice(0, variantLessProducts.length);
  }
  hasToChooseAnyVariant(predefSanitybox);
}

const total = computed(() => {
  return sanityItems.value.reduce((total, sanityItem) => {
    if (sanityItem.hasGroup.value) {
      const selectedVariant = sanityItem.itemGroupList[0].groupVariantList.find(groupVariant => groupVariant.selected);
      return total + (sanityItem.quantity * (selectedVariant ? selectedVariant.variantOutPrice : 0));
    } else {
      return total + (sanityItem.quantity * sanityItem.itemOutPrice);
    }
  }, 0);
});

const canClickVariant = (groupVariant) => {
  return groupVariant.variantOutPrice + total.value < 40;
};

const isVisible = (sanityItem) => {
  if (!sanityItem.itemActive) {
    return false;
  }
  if (sanityItem.hasGroup) {
    const selectedVariant = sanityItem.itemGroupList[0].groupVariantList.find(groupVariant => groupVariant.selected);
    if (!selectedVariant || typeof selectedVariant.variantOutPrice !== 'number') {
      let cheapestVariant = sanityItem.itemGroupList[0].groupVariantList.reduce((a, b) => (a.variantOutPrice < b.variantOutPrice) ? a : b);
      return total.value + cheapestVariant.variantOutPrice < 40 || sanityItem.quantity > 0;
    } else {
      return selectedVariant.variantOutPrice + total.value < 40 || sanityItem.quantity > 0;
    }
  }
  return (total.value + sanityItem.itemOutPrice) < 40 || sanityItem.quantity > 0;
};

const canClickPlus = (sanityItem) => {
  if (sanityItem.hasGroup) {
    const selectedVariant = sanityItem.itemGroupList[0].groupVariantList.find(groupVariant => groupVariant.selected);
    if (!selectedVariant || typeof selectedVariant.variantOutPrice !== 'number') {
      return false;
    }
  }
  return (total.value + sanityItem.itemOutPrice) > 40;
};

const addsanityItem = (sanityItem) => {
  showToast();

  if (sanityItem.hasGroup) {
    const selectedVariant = sanityItem.itemGroupList[0].selectedVariant;
    if (!selectedVariant) {
      return;
    }
    if ((total.value ? total.value : 0) + selectedVariant.variantOutPrice <= 40) {
      sanityItem.quantity++;
    }
  } else {
    if ((total.value ? total.value : 0) + sanityItem.itemOutPrice <= 40) {
      sanityItem.quantity++;
    }
  }
};

const removesanityItem = (sanityItem) => {
  showToast();

  if (sanityItem.quantity > 0) {
    sanityItem.quantity--;
  }
};

const selectVariant = (sanityItem, selectedVariant) => {
  showToast();

  sanityItem.itemGroupList[0].groupVariantList.forEach(variant => {
    variant.selected = variant === selectedVariant;
    if (variant === selectedVariant) {
      sanityItem.itemGroupList[0].selectedVariant = selectedVariant;
    }
  });
  sanityItem.selectedVariant = selectedVariant;
};

const setBoxTypeSelection = (userSel) => {
  showToast();
  isInitialSelection.value = false;

  sanitybox.userSelection = userSel;
}
</script>


<style scoped>
.itemEntry {
  padding: 5px;
}

.itemEntry:hover {
  padding: 5px;
  background-color: rgb(206, 207, 255);
}

.itemEntry.selected {
  background-color: rgb(206, 207, 255);
  border: 1px solid rgb(121, 121, 121);
}

.itemcard {
  padding: 9px;
  margin-bottom: 10px;
}

.itemcard:hover {
  background-color: rgb(206, 207, 255);
}

.sanity-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sanity-item-card {
  display: flex;
  flex-direction: column;
  flex: 1;
  border: 1px solid #ccc;
}

.itemBoxAndDescription {
  display: flex;
  flex-wrap: wrap;
}

.variants {
  display: block;
  justify-content: center;
}

.variant {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
  cursor: pointer;
}

.variants>button {
  margin: 3px;
  padding: 3px;
  justify-content: center;
}

.variant input {
  margin-right: 5px;
}

.controls {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.quantity {
  margin: 0 10px;
}

.error {
  color: red;
  margin-top: 10px;
}

.adjustAmountButtons {
  padding: 7px;
  margin-left: 3px;
  width: auto;
  height: 20px;
}

:deep(.green .p-progressbar-value) {
  background-color: #38a169;
}

:deep(.p-progressbar-label) {
  visibility: hidden;
}

.stickyCard {
  position: fixed;
  top: 230px;
  right: 150px;
}
</style>