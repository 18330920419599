import { defineStore } from "pinia";
import { reactive,ref } from "vue";

export const localStore = defineStore("store", {
  state: () => ({
    notReady:ref(false),
    manuell:ref(false),
    companyData: reactive({}),
    formDataQuestions: reactive({}),
    formDataRegister: reactive({}),
    formDataRegister2: reactive({}),
    warenkorb: reactive({})
  }),
  getters: {
    getWarenkorb(state) {
      return state.warenkorb;
    },
    getCompanyData(state) {
/*       console.log("🚀 ~ file: store.js:19 ~ getCompanyData ~ state.companyData:", state.companyData); //TODO DEBUG
 */      return state.companyData;
    },
    getFormDataQuestions(state) {
/*       console.log("🚀 ~ file: store.js:23 ~ getFormDataQuestions ~ state.formDataQuestions:", state.formDataQuestions); //TODO DEBUG
 */      return state.formDataQuestions;
    },
    getFormDataRegister(state) {
/*       console.log("🚀 ~ file: store.js:27 ~ getFormDataRegister ~ state.formDataRegister:", state.formDataRegister); //TODO DEBUG
 */      return state.formDataRegister;
    },
    getFormDataRegister2(state) {
/*       console.log("🚀 ~ file: store.js:31 ~ getFormDataRegister2 ~ state.formDataRegister2:", state.formDataRegister2); //TODO DEBUG
 */      return state.formDataRegister2;
    },
    getNotReady(state) {
      return state.notReady;
    }
  },
  actions: {
    setWarenkorb(data) {
      this.warenkorb = data;
    },
     setCompanyData(data) {
      this.companyData = data;
    },
    setFormDataQuestions(data) {
      this.formDataQuestions = data;
    },
    setFormDataRegister(data) {
      this.formDataRegister = data;
    },
    setFormDataRegister2(data) {
      this.formDataRegister2 = data;
    }, 
    setNotReady(bool) {
      this.notReady = bool;
    }, 
    setManuell(bool) {
      this.manuell = bool;
    }
  },
  persist: true
});
