import "primeflex/primeflex.min.css";
import "primevue/resources/themes/viva-light/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import "./index.css";
import "./flags.css";

import { createApp,ref } from "vue";
import App from "./App.vue";
import { router } from "./router";
//Primevue und Primevue Plugins
import PrimeVue from "primevue/config";
import AutoComplete from 'primevue/autocomplete';
import BlockUI from 'primevue/blockui';
import Button from 'primevue/button';
import Card from 'primevue/card';
import Calendar from 'primevue/calendar';
import Checkbox from 'primevue/checkbox';
import ConfirmDialog from 'primevue/confirmdialog';
import ConfirmationService from 'primevue/confirmationservice';
import DialogService from 'primevue/dialogservice'
import Dialog from 'primevue/dialog';
import Dropdown from 'primevue/dropdown';
import Fieldset from 'primevue/fieldset';
import Image from 'primevue/image';
import InputText from 'primevue/inputtext';
import InputMask from 'primevue/inputmask';
import InputNumber from 'primevue/inputnumber';
import Listbox from 'primevue/listbox';
import RadioButton from 'primevue/radiobutton';
import Steps from 'primevue/steps';
import Textarea from 'primevue/textarea';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import SelectButton from 'primevue/selectbutton';
import ProgressBar from 'primevue/progressbar'
import {createPinia} from 'pinia';
import piniaPluginPersistedState from "pinia-plugin-persistedstate" //für localstorage pinia plugin

//i18n
import i18n from './i18n';

//sign
import VueSignature from "vue-signature-pad";

//vee-validate
import { Form, Field } from "vee-validate";

//primevue css
import 'primevue/resources/themes/lara-light-blue/theme.css'; // Importiere das PrimeVue-Theme (ersetze es durch dein gewünschtes Theme)
import 'primevue/resources/primevue.min.css'; // Importiere die PrimeVue-Basis-CSS
import 'primeicons/primeicons.css'; // Importiere die PrimeIcons-CSS
import './assets/fonts/fonts.css';

const app = createApp(App);
const pinia = createPinia();
pinia.use(piniaPluginPersistedState); //plugin zu pinia

app.use(pinia);
app.use(PrimeVue, { ripple: true });
app.use(ToastService);
app.use(ConfirmationService);
app.use(DialogService);
app.use(router);
app.use(i18n);
app.use(VueSignature);
//app.use(vuex);

app.component('AutoComplete', AutoComplete);
app.component('Form', Form);
app.component('Field', Field);
app.component('BlockUI', BlockUI);
app.component('Button', Button);
app.component('Card', Card);
app.component('Checkbox', Checkbox);
app.component('ConfirmDialog', ConfirmDialog);
app.component('Dialog', Dialog);
app.component('Dropdown', Dropdown);
app.component('Fieldset', Fieldset);
app.component('Image', Image);
app.component('InputText', InputText);
app.component('InputMask', InputMask);
app.component('InputNumber', InputNumber);
app.component('Listbox', Listbox);
app.component('RadioButton', RadioButton);
app.component('Steps', Steps);
app.component('Textarea', Textarea);
app.component('Toast', Toast);
app.component('SelectButton', SelectButton);
app.component('Calendar', Calendar);
app.component('ProgressBar', ProgressBar);
// Globale Variable erstellen
const stepbar = ref(false);
// Variable global verfügbar machen
app.provide('stepbar', stepbar);

const vm = app.mount("#app");
