<!--StepperRegister.vue-->
<template>
  <div class="StepperRegister">
    <!--     <Toast /> -->
    <Card>
      <template v-slot:subtitle>
        <div>{{ $t("needSelectedData") }}</div>
        <div>- {{ $t("dataOfTheInsured") }}</div>
        <div v-show="formDataQuestions.whereToSendBox === 'qDeliverPackstation'">
          - {{ $t("PackstationAddressData") }}
        </div>
      </template>
      <template v-slot:content>
        <div class="field col-12 md:col-8 mx-auto">
          <h3>{{ $t("qInsurencedPerson") }}</h3>
          <form @submit="submitForm">
            <div class="p-fluid formgrid grid">
              <div class="field col-10 md:col-2">
                <label for="Salutation">{{ $t("salution") }}</label>
                <InputText id="Salutation" v-model="formData.salutation" />
              </div>
              <div class="field col-12 md:col-10"></div>

              <div class="field col-12 md:col-5">
                <label for="firstName">{{ $t("firstName") }}</label>
                <InputText id="firstName" v-model="formData.firstName" />
              </div>
              <div class="field col-12 md:col-5">
                <label for="surName">{{ $t("surName") }}</label>
                <InputText id="surName" v-model="formData.surName" />
              </div>
              <div class="field col-12 md:col-8">
                <label for="street">{{ $t("street") }}</label>
                <InputText id="street" v-model="formData.street" />
              </div>
              <div class="field col-12 md:col-2">
                <label for="hnr">{{ $t("houseNumber") }}</label>
                <InputText id="houseNumber" v-model="formData.houseNumber" />
              </div>
              <div class="field col-12 md:col-2"></div>
              <div class="field col-12 md:col-2">
                <label for="postCode">{{ $t("postCode") }}</label>
                <InputText id="postCode" v-model="formData.postCode" />
              </div>
              <div class="field col-12 md:col-8">
                <label for="city">{{ $t("city") }}</label>
                <InputText id="city" v-model="formData.city" />
              </div>
              <div class="field col-12 md:col-2"></div>
              <div class="field col-12 md:col-3">
                <label for="birthDate">{{ $t("birthDate") }}</label>
                <div class="p-inputgroup">
                  <Calendar
                    v-model="formData.birthDate"
                    showIcon
                    dateFormat="dd.mm.yy"
                    placeholder="01.01.1900"
                    id="birthDate"
                  />
                </div>
              </div>

              <div
                v-show="formDataQuestions.insuranceKind === 'qLegalInsurance'"
                class="field col-12 md:col-4"
              >
                <label for="healthInsurance">{{ $t("healthInsurance") }}</label>
                <AutoComplete
                  v-model="healthInsuranceField"
                  optionLabel="insuName"
                  :suggestions="filteredInsurances"
                  @complete="search"
                >
                  <template #option="{ option }">
                    <div>
                      <div>{{ option.insuName }}</div>
                    </div>
                  </template>
                </AutoComplete>
              </div>
              <div
                v-show="formDataQuestions.insuranceKind === 'qLegalInsurance'"
                class="field col-12 md:col-3"
              >
                <label for="insurancePolicyNumber">{{
                  $t("insurancePolicyNumber")
                }}</label>
                <InputText
                  id="insurancePolicyNumber"
                  v-model="formData.insurancePolicyNumber"
                  :maxlength="10"
                  placeholder="a123456789"
                  @input="checkInputLength"
                />
              </div>

              <!--tostmeldung-->
              <Toast position="bottom-center" group="h">
                <template #message="slotProps">
                  <div
                    class="flex flex-column align-items-center"
                    style="flex: 1"
                  >
                    <div class="text-center">
                      <i
                        class="pi pi-exclamation-triangle"
                        style="font-size: 3rem"
                      ></i>
                      <div class="font-bold text-xl my-3">
                        {{ slotProps.message.summary }}
                      </div>
                    </div>
                    <div class="flex gap-2">
                      <img src="@/assets/MusterKrankenkassenkarte.png" />
                    </div>
                  </div>
                </template>
              </Toast>
              <!--tostmeldung ende-->

              <div class="field col-12 md:col-5">
                <label for="emailAddress">{{ $t("emailAddress") }}</label>
                <InputText
                  id="emailAddress"
                  v-model="formData.emailAddress"
                  placeholder="maxmusterman@email.de"
                />
              </div>
              <div class="field col-12 md:col-5">
                <label for="confirmEmail">{{ $t("confirmEmail") }}</label>
                <InputText
                  id="confirmEmail"
                  v-model="formData.confirmEmail"
                  placeholder="maxmusterman@email.de"
                />
                <!-- @input="checkInputLength" -->
              </div>

              <!-- Packstation -->

              <div
                class="p-fluid formgrid grid"
                v-show="formDataQuestions.whereToSendBox === 'qDeliverPackstation'"
                style="backgroundColor: var(--yellow-300)"
              >
                <h3 class="col-12">{{ $t("packstation") }}</h3>
                <div class="field col-12 md:col-2">
                  <label for="packstationPostNumber">{{
                    $t("packstationPostNumber")
                  }}</label>
                  <InputText
                    id="packstationPostNumber"
                    v-model="formData.packstationPostNumber"
                  />
                </div>
                <div class="field col-12 md:col-3">
                  <label for="packstationNumber">{{
                    $t("packstationNumber")
                  }}</label>
                  <InputText
                    id="packstationNumber"
                    v-model="formData.packstationNumber"
                  />
                </div>
                <div class="field col-12 md:col-2">
                  <label for="packstationPostCode">{{
                    $t("packstationPostCode")
                  }}</label>
                  <InputText
                    id="packstationPostCode"
                    v-model="formData.packstationPostCode"
                  />
                </div>
                <div class="field col-12 md:col-3">
                  <label for="packstationCity">{{
                    $t("packstationCity")
                  }}</label>
                  <InputText
                    id="packstationCity"
                    v-model="formData.packstationCity"
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </template>
      <template v-slot:footer>
        <div class="grid grid-nogutter justify-content-between">
          <Button
            :label="$t('back')"
            @click="prevPage()"
            icon="pi pi-angle-left"
          />
          <Button
            :label="$t('next')"
            @click="nextPage()"
            icon="pi pi-angle-right"
            iconPos="right"
          />
        </div>
      </template>
    </Card>
  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  onUpdated,
  onBeforeUnmount,
  getCurrentInstance,
  reactive,
  watch
} from "vue";
import Calendar from "primevue/calendar";
import { useToast } from "primevue/usetoast";
import { useI18n } from "vue-i18n";
import InsuranceModel from "../Model/insurance-model";
import getAxiosDataWithModel from "../Model/axios-model-loader";
import { ValidationSchemaRegister } from "../validation/ValidationSchemaRegister";
import {localStore} from "@/store/store";

const store = localStore();

const dayjs = require("dayjs"); //datumkonvertierung


//let ValidationSchemaRegister = null;

/* Deklariere eine Variable, um auf healthInsurance zuzugreifen */

const toast = useToast();
const { t } = useI18n();
const { emit } = getCurrentInstance();

//let formDataRegister = inject("formDataRegister"); // zum befüllen der Werte aus Register
//let formDataQuestions = inject("formDataQuestions"); // wegen abfrage packstation
const formDataRegister = reactive(store.formDataRegister); // zum befüllen der Werte aus Register
const formDataQuestions = reactive(store.formDataQuestions); // wegen abfrage packstation

const healthInsuranceField = ref("");
//const healthInsuranceValidation = ref(false);
const formData = reactive({
  salutation: "",
  firstName: "",
  surName: "",
  street: "",
  houseNumber: "",
  postCode: "",
  city: "",
  birthDate: "",
  emailAddress: "",
  insurancePolicyNumber: "",
  healthInsurance:healthInsuranceField,
  insuranceName:"",
  packstationPostNumber: "",
  packstationPostNumber: "",
  packstationNumber: "",
  packstationPostCode: "",
  packstationCity: "",
});

//im setupbereich setter intitalisieren
store.setFormDataRegister(formData);


/* watch(() => healthInsuranceField.value, (newValue) => {
   formData.insuranceName = newValue ? newValue : ""; 
});
 */
/* Für Autocomplete */
const filteredInsurances = ref([]);
const insuranceDataRef = ref(null);

// Diese Form ist notwendig da auch eigene validierung angewendet wird,
// catch würde die Funktion vorzeitig beenden und daher sind zu catchende
// am ende... _value ist notwendig um den tatsächlich value wert zu erhalten

const getInvalidFields = () => {
  Object.assign(formData,formDataQuestions);
  console.log("🚀 ~ file: StepperRegister.vue:280 ~ getInvalidFields ~ formData:", formData)
  
  const invalidFields = [];
  try {
    ValidationSchemaRegister.validateSync(formData, {
      abortEarly: false,
    });
  } catch (error) {

    error.inner.forEach((fieldError) => {
      invalidFields.push([fieldError.path,fieldError.message]);
    });
  }
/*   const ifs = [...new Set(invalidFields)];
  console.log("🚀 ~ file: StepperRegister.vue:300 ~ getInvalidFields ~ invalidFields:", invalidFields) */
const invalidFieldsGrouped=groupBy(invalidFields,0);

  return invalidFieldsGrouped;
};
/* 
const showError = () => {
  toast.removeAllGroups();
  const invalidFields = getInvalidFields();
  invalidFields.forEach(([fieldName,message]) => {
    let translatedField = t(fieldName)+" "+t(message);
    const transe = t("pleaseFillField");
    toast.add({
      severity: "error",
      summary: transe,
      detail: translatedField,
      life: 4000,
    });
  });
};
 */
 function groupBy(array, key) {
  return array.reduce((result, item) => {
    const groupKey = item[key];
    if (!result[groupKey]) {
      result[groupKey] = [];
    }
    result[groupKey].push(item);
    return result;
  }, {});
}

const showError = () => {
  toast.removeAllGroups();
  const invalidFieldsGrouped = getInvalidFields();

  Object.entries(invalidFieldsGrouped).forEach(([fieldName, errors]) => {
    const translatedField = t(fieldName);
    const errorMessages = errors.map(([_, message]) => t(message));
    const errorMessage = errorMessages.join("\n\r");

    toast.add({
      severity: "error",
      summary: t("pleaseFillField"),
      detail: `${translatedField}: ${errorMessage}`,
      life: 4000,
    });
  });
};

const showTemplate = () => {
  toast.removeAllGroups();
  const transe = t("policyNumberInformation");
  toast.add({
    severity: "error",
    summary: transe,
    detail: "",
    life: 2000,
  });
};
const validationErrors = ref({ insurancePolicyNumber: false });

const checkInputLength = () => {
  const { insurancePolicyNumber } = formData;
  const isValid = /^[A-Za-z](\d*)$/.test(insurancePolicyNumber);

  if (
    insurancePolicyNumber.length > 10 ||
    (!isValid &&
      insurancePolicyNumber.length > 0 &&
      insurancePolicyNumber.value != "")
  ) {
    validationErrors.value.insurancePolicyNumber = true;
    showTemplate();
  } else {
    validationErrors.value.insurancePolicyNumber = false;
  }
};

/* function checkHealthInsurance(){
    return (formDataQuestions.insuranceKind=='qLegalInsurance') &&  (formData.healthInsurance == "" ||
    formData.healthInsurance === null)
} */

function nextPage() {
  formData.insuranceName = healthInsuranceField.value.insuName?healthInsuranceField.value.insuName:healthInsuranceField.value;
  const groupedKeys = Object.keys(getInvalidFields());
  if (
    groupedKeys.length > 0) {
    showError();
  } else {
    const date = dayjs(formDataRegister.birthDate);
    formDataRegister.birthDate = date.format("DD.MM.YYYY");
    const pageIndex = 2;
    emit("next-page", {
      formData: { formData },
      pageIndex,
    });
  }
}

function prevPage() {
  const pageIndex = 2;
  emit("prev-page", { pageIndex });
}

/* Für die Filterung und suche für Autocomlete */
const search = (event) => {
  setTimeout(() => {
    if (!event.query.trim().length) {
      filteredInsurances.value =
        healthInsuranceField.value?.insuranceList ||
        insuranceDataRef.value?.insuranceList ||
        [];
    } else {
      filteredInsurances.value =
        insuranceDataRef.value?.insuranceList?.filter((insurance) => {
          return insurance.insuName
            .toLowerCase()
            .startsWith(event.query.toLowerCase());
        }) || [];
    }
  }, 250);
};


onUpdated(async () => {
  //store.setFormDataRegister(formData);
  console.log("🚀 ~ file: StepperRegister.vue:425 ~ onUpdated ~ formData:", formData)
  console.log("🚀 ~ file: StepperRegister.vue:425 ~ onUpdated ~ formDataRegister:", formDataRegister)
});

onBeforeUnmount(() => {
  toast.removeAllGroups();
});

onMounted(async () => {
  window.scrollTo({
    top: 0,
    behavior: "auto", // Scrollt sofort nach oben, ohne Animation
  });
  try {
    const url = process.env.VUE_APP_BASE_API_URL_PATIENT + "/insurance-all";
    const insuranceData = await getAxiosDataWithModel(url, InsuranceModel);
    filteredInsurances.value = insuranceData.insuranceList;
    // Speichere die insuranceData in einer Ref-Variable, um später darauf zugreifen zu können
    insuranceDataRef.value = insuranceData;
  } catch (error) {
    console.error("Error fetching insurance data:", error);
  }
});
</script>
