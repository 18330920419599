<template>
  <div class="stepsdemo-content">
    <Card>
      <Toast group="w" />
      <template v-slot:title>
        {{ $t("sanityBox.stepperHeadTitle") }}
      </template>
      <template v-slot:subtitle>
        <!-- Untertitel -->
      </template>
      <template v-slot:content>
        <div class="p-fluid">
          <BoxConfig></BoxConfig>
        </div>
      </template>
      <template v-slot:footer>
        <div class="grid grid-nogutter justify-content-between">
          <i></i>
          <Button :label="$t('next')" @click="nextPage" icon="pi pi-angle-right" iconPos="right" />
        </div>
      </template>
    </Card>
  </div>
</template>

<script setup>
import { ref, onMounted, getCurrentInstance, inject, reactive } from 'vue';
import BoxConfig from './BoxConfig.vue';
import { useToast } from "primevue/usetoast";
import Toast from 'primevue/toast';
import { useI18n } from "vue-i18n";
import { localStore } from "@/store/store";

const store = localStore();
const toast = useToast();
const { t } = useI18n();

const boxValidate = inject("boxValidate");
const items = ref('');


const stepbar = inject("stepbar");
stepbar.value = true;

onMounted(() => {
  /*  platzhalter */
});

const custom2Box=()=> {
  const warenkorb = store.warenkorb;
  if (warenkorb.boxtype === "custom"){
    console.log("🚀 ~ file: StepperBox.vue:52 ~ custom2Box ~ warenkorb:", warenkorb)
    if (!warenkorb.selectedBox){
      warenkorb.selectedBox=reactive({});
      warenkorb.selectedBox.productItemList=ref([]);
    }
    warenkorb.selectedBox.productItemList=warenkorb.selectedSanityItems;
    warenkorb.selectedBox.productName="selbst Konfiguriert";
  }
}

const instance = getCurrentInstance();
const nextPage = () => {
  if (instance) {
    custom2Box();
    const { emit } = instance;

    let violoations = boxValidate.validate();
    if (violoations.length == 0) {
      toast.removeAllGroups();
      emit('next-page', { pageIndex: 0 });
    } 
    violoations.forEach(element => {
      toast.removeAllGroups();
      toast.add({ severity: 'error', summary: t("sanityBox.validate.error.validationErrorTitle"), detail: element, life: 3000 });
    });
  } else {
    console.error('Aktuelle Instanz kann nicht abgerufen werden.');
  }
};
</script>
