<template>
  <!--Stepperquestion-->
  <div class="StepperQuestion">
    <Toast />
    <Card>
      <template v-slot:content>
        <div>
          <form @submit="submitForm" class="flex flex-column gap-2">
            <div class="field col-12 md:col-8 mx-auto">
              <!-- <ausen div -->
              <!-- Frage 1 -->
              <Card style="p-card ">
                <template #title>{{ $t("qNursingKind") }}</template>
                <template #content>
                  <div class="flex flex-column gap-3">
                    <div class="flex align-items-center">
                      <RadioButton
                        v-model="formData.careType"
                        inputId="qCarePerson"
                        name="NursingKind"
                        value="qCarePerson"
                      />
                      <label for="qCarePerson" class="ml-2">{{
                        $t("qCarePerson")
                      }}</label>
                    </div>
                    <div class="flex align-items-center">
                      <RadioButton
                        v-model="formData.careType"
                        inputId="qNursingService"
                        name="NursingKind"
                        value="qNursingService"
                      />
                      <label for="qNursingService" class="ml-2">{{
                        $t("qNursingService")
                      }}</label>
                    </div>
                  </div>
                </template>
              </Card>
              <p></p>
              <!-- Frage 2 -->
              <Card style="p-card ">
                <template #title>{{ $t("qInsuranceKind") }}</template>
                <template #content>
                  <div class="flex flex-column gap-3">
                    <div class="flex align-items-center">
                      <RadioButton
                        v-model="formData.insuranceKind"
                        inputId="qLegalInsurance"
                        name="InsuranceKind"
                        value="qLegalInsurance"
                      />
                      <label for="qLegalInsurance" class="ml-2">{{
                        $t("qLegalInsurance")
                      }}</label>
                    </div>
                    <div class="flex align-items-center">
                      <RadioButton
                        v-model="formData.insuranceKind"
                        inputId="qPrivatInsurance"
                        name="InsuranceKind"
                        value="qPrivatInsurance"
                      />
                      <label for="qPrivatInsurance" class="ml-2">{{
                        $t("qPrivatInsurance")
                      }}</label>
                    </div>
                    <div class="flex align-items-center">
                      <RadioButton
                        v-model="formData.insuranceKind"
                        inputId="qSocialWelfareOffice"
                        name="InsuranceKind"
                        value="qSocialWelfareOffice"
                      />
                      <label for="qSocialWelfareOffice" class="ml-2">{{
                        $t("qSocialWelfareOffice")
                      }}</label>
                    </div>
                    <div class="flex align-items-center">
                      <RadioButton
                        v-model="formData.insuranceKind"
                        inputId="qEligibility"
                        name="InsuranceKind"
                        value="qEligibility"
                      />
                      <label for="qEligibility" class="ml-2">{{
                        $t("qEligibility")
                      }}</label>
                    </div>
                  </div>
                </template>
              </Card>
              <p></p>
              <!-- Frage 3 -->
              <Card style="p-card ">
                <template #title>{{ $t("qCareLevel") }}</template>
                <template #content>
                  <div class="flex align-items-center">
                    <Dropdown
                      v-model="formData.careLevel"
                      :options="careLevelOptions"
                      :placeholder="$t('qSelect')"
                    />
                  </div>
                </template>
              </Card>
              <p></p>
              <!-- Frage 4 -->
              <Card style="p-card ">
                <template #title>{{ $t("qPackageDeliverTime") }}</template>
                <template #content>
                  <div class="flex align-items-center">
                    <Dropdown
                      v-model="formData.packageDeliverSelection"
                      :options="packageDeliverTimeOptions"
                      :optionLabel="option => $t(option.label)"
                      :placeholder="$t('qSelect')"
                    />
                  </div>
                </template>
              </Card>
              <p></p>
              <!-- Frage 5 -->
              <Card style="p-card ">
                <template #title>{{ $t("qDeliverQuestion") }}</template>
                <template #content>
                  <div class="flex flex-column gap-3">
                    <div class="flex align-items-center">
                      <RadioButton
                        v-model="formData.whereToSendBox"
                        inputId="qDeliverInsurencedPerson"
                        name="DeliverQuestion"
                        value="qDeliverInsurencedPerson"
                      />
                      <label for="qDeliverInsurencedPerson" class="ml-2">{{
                        $t("qInsurencedPerson")
                      }}</label>
                    </div>
                    <div class="flex align-items-center">
                      <RadioButton
                        v-model="formData.whereToSendBox"
                        inputId="qDeliverPackstation"
                        name="DeliverQuestion"
                        value="qDeliverPackstation"
                      />
                      <label for="qDeliverPackstation" class="ml-2">{{
                        $t("qPackstation")
                      }}</label>
                    </div>
                    <div class="flex align-items-center">
                      <RadioButton
                        v-model="formData.whereToSendBox"
                        inputId="qDeliverNursingService"
                        name="DeliverQuestion"
                        value="qDeliverNursingService"
                      />
                      <label for="qDeliverNursingService" class="ml-2">{{
                        $t("qNursingService")
                      }}</label>
                    </div>
                    <div class="flex align-items-center">
                      <RadioButton
                        v-model="formData.whereToSendBox"
                        inputId="qDeliverCarePerson"
                        name="DeliverQuestion"
                        value="qDeliverCarePerson"
                      />
                      <label for="qDeliverCarePerson" class="ml-2">{{
                        $t("qCarePerson")
                      }}</label>
                    </div>
                  </div>
                </template>
              </Card>
              <p></p>
              <!-- Frage 6 -->
              <Card style="p-card ">
                <template #title>{{ $t("qTerminateContract") }}</template>
                <template #content>
                  <div class="flex flex-column gap-3">
                    <div class="flex align-items-center">
                      <RadioButton
                        v-model="formData.qTerminateContract"
                        inputId="qTerminateYes"
                        name="TerminateQuestion"
                        value="qTerminateYes"
                      />
                      <label for="qTerminateYes" class="ml-2">{{
                        $t("qTerminateYes")
                      }}</label>
                    </div>
                    <div class="flex align-items-center">
                      <RadioButton
                        v-model="formData.qTerminateContract"
                        inputId="qTerminateNo"
                        name="TerminateQuestion"
                        value="qTerminateNo"
                      />
                      <label for="qTerminateNo" class="ml-2">{{
                        $t("qTerminateNo")
                      }}</label>
                    </div>
                  </div>
                </template>
              </Card>
              <!-- Frage 7 -->
              <Card v-if="anualItem" style="p-card ">
                <template #title>{{ $t("qSendAnualItems") }}
                
                <div class="flex p-2 mt-2 border-round-md border-primary-500 border-dashed	 font-normal">
                    <div class="flex justify-content-center align-items-center">
                  <img class="w-2" :src="anualItem.itemImageUrl">
                  {{anualItem.anualQuantity}} Stk. x {{anualItem.itemName}}
                </div>
              </div>
                
                </template>
                <template #content>
                  <div class="flex flex-column gap-3">
                    <div class="flex align-items-center">
                      <RadioButton
                        v-model="formData.qSendAnualItems"
                        inputId="qSendAnualItemsYes"
                        name="SendAnualItemsQuestion"
                        value="qSendAnualItemsYes"
                      />
                      <label for="qSendAnualItemsYes" class="ml-2">{{
                        $t("qSendAnualItemsYes")
                      }}</label>
                    </div>
                    <div class="flex align-items-center">
                      <RadioButton
                        v-model="formData.qSendAnualItems"
                        inputId="qSendAnualItemsNo"
                        name="SendAnualItemsQuestion"
                        value="qSendAnualItemsNo"
                      />
                      <label for="qSendAnualItemsNo" class="ml-2">{{
                        $t("qSendAnualItemsNo")
                      }}</label>
                    </div>
                  </div>
                </template>
              </Card>
            </div>
            <div class="grid grid-nogutter justify-content-between">
              <Button
                :label="$t('back')"
                @click="prevPage()"
                icon="pi pi-angle-left"
              />
              <Button
                :label="$t('next')"
                @click="nextPage()"
                icon="pi pi-angle-right"
                iconPos="right"
                :class="{}"
              />
            </div>
          </form>
        </div>
      </template>
    </Card>
  </div>
</template>

<script setup>
import { useToast } from "primevue/usetoast";
import { getCurrentInstance, inject,ref, reactive, onMounted,onUpdated, onBeforeUnmount   } from "vue";

import { useI18n } from "vue-i18n";
import { ValidationSchemaQuestion } from "../validation/ValidationSchemaQuestion";
import {localStore} from "@/store/store";

const store = localStore();
const anualItem=ref(store.getCompanyData.anualItem);
console.log("🚀 ~ file: StepperQuestions.vue:273 ~ store.getCompanyData:", store.getCompanyData)
const stepbar=inject("stepbar");
const { t } = useI18n();
/* Feste Werte wurden nicht vom Server geholt weil diese Werte Fest sind und sich
in naher Zukunft nicht ändern werden */
const careLevelOptions = [1, 2, 3, 4, 5, 6];
const packageDeliverTimeOptions = ref([
    {label: "beginningOfTheMonth", value: 1 },
    {label: "middleOfTheMonth", value: 2 },
    {label: "endOfTheMonth", value: 3 },
  ]);
const toast = useToast();

//damit die in den Funktionen t wie im Template $t verwendet werden kann

const { emit } = getCurrentInstance();

/* Der Konstante formDataQuestions wird ein inject ausgeführt um vom Provider
aus der App.vue das Objekt zu zuweisen */
//const formDataQuestions = inject("formDataQuestions");
//const formDataQuestions = store.formDataQuestions;

/* Die Variablen von den Radiobutton mit den Werten aus dem Provider füllen
es könnte auch mit "" leerem String gesetzt werden, aber so wird die Arbeit
auch beim Debuggen erleicht, weil formDataQuestions in App.vue proided wurde
und dort anfangwerte vergeben werden können */

const formData = reactive({
  careLevel: "",
  whereToSendBox: "",
  qInsuranceKind: "",
  qNursingKind: "",
  qTerminateContract: "",
  packageDeliverSelection: "",
  qSendAnualItems: "",
});

//im setupbereich setter intitalisieren, damit es reactive die änderung übernimmt
store.setFormDataQuestions(formData);

const getInvalidFields = () => {
  const invalidFields = [];
  try {
    ValidationSchemaQuestion.validateSync(formData, { abortEarly: false });
  } catch (error) {
    error.inner.forEach((fieldError) => {
      invalidFields.push(fieldError.path);
    });
  }
  return invalidFields;
};

const showError = () => {
  toast.removeAllGroups();
  const invalidFields = getInvalidFields();
  invalidFields.forEach((fieldName) => {
    let translatedField = t(fieldName);

    /*     In summary kann die t funktion nicht direkt verwendet werden,
    daher vorher der Konstanstante transe zuweisen */
    const transe = t("pleaseAnswerfollowingQuestions");
    toast.add({
      severity: "error",
      summary: transe,
      detail: translatedField,
      life: 2000,
    });
  });
};

onBeforeUnmount(() => {
  toast.removeAllGroups();
});

onUpdated(() => {
  stepbar.value=true;
}),

onMounted(() => {
  window.scrollTo({
    top: 0,
    behavior: 'auto' // Scrollt sofort nach oben, ohne Animation
  });
});

function nextPage() {
  if (getInvalidFields().length > 0) {
    showError();
  } else {
    console.log("🚀 ~ file: StepperQuestions.vue:306 ~ formData:", formData)
    console.log("🚀 ~ file: StepperQuestions.vue:307 ~ nextPage ~ formDataQuestions:", store.formDataQuestions)
    const pageIndex = 1;
    emit("next-page", { pageIndex });
  }
}

function prevPage() {
  const pageIndex = 1;
  emit("prev-page", { pageIndex });
}


</script>
<style>
.p-card .p-card-title {
  font-size: 1rem!important;
}
</style>