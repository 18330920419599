import { createRouter, createWebHistory } from "vue-router";
import Welcome from "../pages/Welcome.vue";
import StepperBox from "../components/StepperBox.vue";
import StepperRegister from "../components/StepperRegister.vue";
import StepperRegister2 from "../components/StepperRegister2.vue";
import StepperQuestions from "../components/StepperQuestions.vue";
import StepperConfirm from "../components/StepperConfirm.vue";
import HandSign from "../components/HandSign.vue";
import ServerAnfrage from "../api/ServerAnfrage.vue";
import VL from "../components/InsuranceListAutoComplete.vue";
import { localStore } from "@/store/store";


export const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: "/", component: Welcome, name: "HOME" },
    { path: "/start", component: StepperBox, name: "START" },
    { path: "/questions", component: StepperQuestions, name: "QUESTIONS" },
    { path: "/register", component: StepperRegister, name: "REGISTER" },
    { path: "/register2", component: StepperRegister2, name: "REGISTER2" },
    { path: "/confirmation", component: StepperConfirm, name: "CONFIRMATION" },
    { path: "/handsign", component: HandSign, name: "SIGNATURE" },
    { path: "/sa", component: ServerAnfrage, name: "SERVERANFRAGE" },
    { path: "/vl", component: VL, name: "VL" },

    { path: "/:companyId", component: Welcome, name: "COMPANYID" },

  ]
});


//Für die Home seite wird das padding wegen dem Video entfernt
router.afterEach((to) => {
  if (to.name === "HOME" || to.path === `/${to.params.companyId}`) {
    document.body.style.padding = "0rem";
    document.body.style.overflow = 'hidden';

  } else {
    document.body.style.padding = "1rem";
    document.body.style.overflow = 'auto';
  }
});

/* router.beforeEach((to, from, next) => {
  const store=userStore(); 
  if (store.getCompanyData) {
    next("/NotReady"); // Weiterleitung zur Not Ready Seite
  } else {
    next(); // Weiterleitung zu der angeforderten Seite
  }
}); */

