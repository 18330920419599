import * as yup from 'yup';
import isEmailValidator from 'validator/lib/isEmail';


  export const ValidationSchemaRegister2 = yup.object({
    caregiverFirstName: yup.string().when('careType', {is: 'qCarePerson',then: () => yup.string().required('validation.isARequiredField')}),
    caregiverSurName: yup.string().when('careType', {is: 'qCarePerson',then: () => yup.string().required('validation.isARequiredField')}),
    caregiverStreet: yup.string().when('careType', {is: 'qCarePerson',then: () => yup.string().required('validation.isARequiredField')}),
    caregiverHouseNumber: yup.string().when('careType', {is: 'qCarePerson',then: () => yup.string().required('validation.isARequiredField')}),
    caregiverPostCode: yup.string().when('careType', {is: 'qCarePerson',then: () => yup.string().required('validation.isARequiredField')}),
    caregiverCity: yup.string().when('careType', {is: 'qCarePerson',then: () => yup.string().required('validation.isARequiredField')}),
    caregiverPhone: yup.string().when('careType', {is: 'qCarePerson',then: () => yup.string().required('validation.isARequiredField')}),
    toTerminateCompanyName: yup.string().when('qTerminateContract', {is: 'qTerminateYes',then: () => yup.string().required('validation.isARequiredField')}),
    toTerminateStreet: yup.string().when('qTerminateContract', {is: 'qTerminateYes',then: () => yup.string().required('validation.isARequiredField')}),
    toTerminateHouseNumber: yup.string().when('qTerminateContract', {is: 'qTerminateYes',then: () => yup.string().required('validation.isARequiredField')}),
    toTerminatePostCode: yup.string().when('qTerminateContract', {is: 'qTerminateYes',then: () => yup.string().required('validation.isARequiredField')}),
    toTerminateCity: yup.string().when('qTerminateContract', {is: 'qTerminateYes',then: () => yup.string().required('validation.isARequiredField')}),
    nursingServiceCompany: yup.string().when('careType', {is: 'qNursingService',then: () => yup.string().required('validation.isARequiredField')}),
    nursingServiceStreet: yup.string().when('careType', {is: 'qNursingService',then: () => yup.string().required('validation.isARequiredField')}),
    nursingServiceHouseNumber: yup.string().when('careType', {is: 'qNursingService',then: () => yup.string().required('validation.isARequiredField')}),
    nursingServicePostCode: yup.string().when('careType', {is: 'qNursingService',then: () => yup.string().required('validation.isARequiredField')}),
    nursingServiceCity: yup.string().when('careType', {is: 'qNursingService',then: () => yup.string().required('validation.isARequiredField')}),
    nursingServicePhone: yup.string().when('careType', {is: 'qNursingService',then: () => yup.string().required('validation.isARequiredField')}),
  });
