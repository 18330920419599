<template>
    <div id="app">
      <Button @click="showTemplate" label="Unterschreiben und abschicken" />
      <Toast position="bottom-center" group="bc">
        <template #message="slotProps">
          <div class="flex flex-column align-items-center" style="flex: 1">
            <div class="text-center">
              <i></i>
              <div class="font-bold text-xl my-2">
                {{ slotProps.message.summary }}
              </div>
            </div>
            <div class="flex gap-1">
              <div class="">
                <Button @click="clear" label="Löschen" class="p-button-danger" />
                <Button @click="undo" label="Rückgängig" class="p-button-secondary" />
                <Button @click="save" label="Abschicken" class="p-button-success" />
                <div>
                  <VueSignaturePad
                    id="signature"
                    width="320px"
                    height="200px"
                    ref="signaturePad"
                    :options="options"
                  />
                </div>
                <div class="line-1"></div>
              </div>
            </div>
          </div>
        </template>
      </Toast>
    </div>
  </template>
  
  <script setup>
  import { ref } from 'vue';
  import { useToast } from 'primevue/usetoast';
  import axios from 'axios';
  
  const toast = useToast();
  const options = ref({
    penColor: "#00f",
    
  });
  
  const signaturePad = ref(null);
  
  const showTemplate = () => {
    toast.add({
      severity: 'info',
      summary: 'Unterschreiben & Abschicken',
      detail: '',
      group: 'bc',
    });
  };
  
  const undo = () => {
    if (signaturePad.value) {
      signaturePad.value.undoSignature();
    }
  };
  
  const clear = () => {
    if (signaturePad.value) {
      signaturePad.value.clearSignature();
    }
  };


 
  const save = () => {
  if (signaturePad.value) {
    const { isEmpty, data } = signaturePad.value.saveSignature();
    if (isEmpty) {
      alert("Bitte vorerst unterschreiben.");
    } else {
/*       const signatureData = {
        patientId: "123131321",
        signature: data,
      }; */
      console.log(data);
      const formData = new FormData();
        formData.append('sign_file', data);
        formData.append('patientId', '123131321');
        /* formData.append('123131321', JSON.stringify(signatureData)); */

/*       axios
        .post('https://stagingapi.sanitybox.de/api/patient/upload-signature', signatureData) */
        axios
        .post('https://stagingapi.sanitybox.de/api/patient/upload-signature', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(response => {
          alert("Abgeschickt");
          console.log(response.data);
        })
        .catch(error => {
          console.error(error);
        });
    }
  }
};


  </script>
  
  <style>
  #signature {
    border: double 3px transparent;
    border-radius: 5px;
    background-image: linear-gradient(white, white),
      radial-gradient(circle at top left, #000000, #000000);
    background-origin: border-box;
    background-clip: content-box, border-box;
  }
  
  .container {
    width: "100%";
    padding: 0px;
  }
  
  .line-1 {
    margin-top: -30px;
    height: 0px;
    border-style: dashed;
    size: 10px;
    border-color: #808080;
    width: 96%;
    margin-left: 10px;
    width: 290px;
    margin-bottom: 20px;
  }
  
  .line-1::before {
    content: "Unterschrift / Signature / Imza ";
    font-size: smaller;
    color: #808080;
    position: relative;
    top: 5px;
    left: 0px;
  }
  </style>
  