class InsuranceModel {
  constructor(data = {}) {
    this.insuranceList = [];
    this.fromJson(data);
  }

  fromJson(data) {
    if (Array.isArray(data.insuranceList)) {
      this.insuranceList = data.insuranceList.map((insuranceData) => ({
        insuIdNum: insuranceData.insuIdNum,
        insuName: insuranceData.insuName,
        insuShort: insuranceData.insuShort
      }));
    }
    return this;
  }

  toJson() {
    return {
      insuranceList: this.insuranceList
    };
  }
  getInsuranceNames() {
    return Promise.resolve(this.insuranceList.insuName);
  }
}

export default InsuranceModel;
