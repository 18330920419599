<!--StepperRegister2.vue-->

<template>
  <div class="StepperRegister2">
    <Toast />
    <Card>
      <template v-slot:subtitle>
        <div>
          {{ $t("needSelectedData") }}
        </div>
        <div v-show="formDataQuestions.careType == 'qNursingService'">
          - {{ $t("dataNursingService") }}
        </div>
        <div v-show="formDataQuestions.careType == 'qCarePerson'">
          - {{ $t("caregiverInformation") }}
        </div>
        <div v-show="formDataQuestions.qTerminateContract == 'qTerminateYes'">
          - {{ $t("dataTerminationCompany") }}
        </div>
      </template>
      <template v-slot:content>
        <div class="field col-12 md:col-8 mx-auto">
          <!-- Pflegeperson -->
          <div
            class="field col-12"
            style="backgroundColor: var(--purple-100)"
            v-show="formDataQuestions.careType == 'qCarePerson'"
          >
            <h3>{{ $t("qCarePerson") }}</h3>
            <form @submit="submitForm">
              <div class="p-fluid formgrid grid">
                <div class="field col-10 md:col-2">
                  <label for="caregiverSalutation">{{ $t("salution") }}</label>
                  <InputText
                    id="caregiverSalutation"
                    v-model="formData.caregiverSalutation"
                  />
                </div>
                <div class="field col-12 md:col-10"></div>
                <div class="field col-12 md:col-5">
                  <label for="caregiverFirstName">{{
                    $t("caregiverFirstName")
                  }}</label>
                  <InputText
                    id="caregiverFirstName"
                    v-model="formData.caregiverFirstName"
                  />
                </div>
                <div class="field col-12 md:col-5">
                  <label for="caregiverSurName">{{
                    $t("caregiverSurName")
                  }}</label>
                  <InputText
                    id="caregiverSurName"
                    v-model="formData.caregiverSurName"
                  />
                </div>
                <div class="field col-12 md:col-8">
                  <label for="caregiverStreet">{{
                    $t("caregiverStreet")
                  }}</label>
                  <InputText
                    id="caregiverStreet"
                    v-model="formData.caregiverStreet"
                  />
                </div>
                <div class="field col-12 md:col-2">
                  <label for="caregiverHouseNumber">{{
                    $t("caregiverHouseNumber")
                  }}</label>
                  <InputText
                    id="caregiverHouseNumber"
                    v-model="formData.caregiverHouseNumber"
                  />
                </div>
                <div class="field col-12 md:col-2"></div>
                <div class="field col-12 md:col-2">
                  <label for="caregiverPostCode">{{
                    $t("caregiverPostCode")
                  }}</label>
                  <InputText
                    id="caregiverPostCode"
                    v-model="formData.caregiverPostCode"
                  />
                </div>
                <div class="field col-12 md:col-8">
                  <label for="caregiverCity">{{ $t("caregiverCity") }}</label>
                  <InputText
                    id="caregiverCity"
                    v-model="formData.caregiverCity"
                  />
                </div>
                <div class="field col-12 md:col-4">
                  <label for="caregiverPhone">{{ $t("caregiverPhone") }}</label>
                  <InputText
                    id="caregiverPhone"
                    v-model="formData.caregiverPhone"
                  />
                </div>
                <div class="field col-12 md:col-6">
                  <label for="caregiverRelationship">{{
                    $t("caregiverRelationship")
                  }}</label>
                  <InputText
                    id="caregiverRelationship"
                    v-model="formData.caregiverRelationship"
                  />
                </div>
              </div>
            </form>
          </div>
          <!-- Plegeperson ende-->

          <!-- Pflegedienst -->

          <div
            class="field col-12"
            style="backgroundColor: var(--purple-100)"
            v-show="formDataQuestions.careType == 'qNursingService'"
          >
            <h3>Pflegedienst</h3>
            <div class="p-fluid formgrid grid">
              <!-- v-show="formData.careType === 'qNursingService'"> -->

              <div class="field col-12 md:col-8">
                <label for="nursingServiceCompany">{{$t('nursingServiceCompany')}}</label>
                <InputText
                  id="nursingServiceCompany"
                  v-model="formData.nursingServiceCompany"
                />
              </div>
              <div class="field col-8">
                <label for="nursingServiceStreet">{{$t('nursingServiceStreet')}}</label>
                <InputText
                  id="nursingServiceStreet"
                  v-model="formData.nursingServiceStreet"
                />
              </div>
              <div class="field col-12 md:col-2">
                <label for="nursingServiceHouseNumber">{{$t('nursingServiceHouseNumber')}}</label>
                <InputText
                  id="nursingServiceHouseNumber"
                  v-model="formData.nursingServiceHouseNumber"
                />
                <div class="field col-12 md:col-2"></div>
              </div>
              <div class="field col-12 md:col-2"></div>
              <div class="field col-12 md:col-2">
                <label for="nursingServicePostCode">{{$t('nursingServicePostCode')}}</label>
                <InputText
                  id="nursingServicePostCode"
                  v-model="formData.nursingServicePostCode"
                />
              </div>
              <div class="field col-12 md:col-8">
                <label for="nursingServiceCity">Ort</label>
                <InputText
                  id="nursingServiceCity"
                  v-model="formData.nursingServiceCity"
                />
              </div>
              <div class="field col-12 md:col-4">
                <label for="nursingServicePhone">{{$t('nursingServicePhone')}}</label>
                <InputText
                  id="nursingServicePhone"
                  v-model="formData.nursingServicePhone"
                />
              </div>
            </div>
          </div>

          <!-- Pflegedienst ende-->

          <!-- Kündigungsdaten-->
          <div
            class="field col-12"
            style="backgroundColor: var(--highlight-bg)"
            v-show="formDataQuestions.qTerminateContract == 'qTerminateYes'"
          >
            <div class="field col-12"></div>

            <h3>{{ $t("qcancelCurrentCarePackageCompany") }}</h3>
            <div class="p-fluid formgrid grid">
              <div class="field col-12 md:col-5">
                <label for="toTerminateCompanyName">{{
                  $t("toTerminateCompanyName")
                }}</label>

                <InputText
                  id="toTerminateCompanyName"
                  v-model="formData.toTerminateCompanyName"
                />
              </div>
              <div class="field col-12 md:col-5"></div>
              <div class="field col-12 md:col-8">
                <label for="toTerminateStreet">{{
                  $t("toTerminateStreet")
                }}</label>
                <InputText
                  id="toTerminateStreet"
                  v-model="formData.toTerminateStreet"
                />
              </div>
              <div class="field col-12 md:col-2">
                <label for="toTerminateHouseNumber">{{
                  $t("toTerminateHouseNumber")
                }}</label>
                <InputText
                  id="toTerminateHouseNumber"
                  v-model="formData.toTerminateHouseNumber"
                />
              </div>
              <div class="field col-12 md:col-2"></div>
              <div class="field col-12 md:col-2">
                <label for="toTerminatePostCode">{{
                  $t("toTerminatePostCode")
                }}</label>
                <InputText
                  id="toTerminatePostCode"
                  v-model="formData.toTerminatePostCode"
                />
              </div>
              <div class="field col-12 md:col-8">
                <label for="toTerminateCity">{{ $t("toTerminateCity") }}</label>
                <InputText
                  id="toTerminateCity"
                  v-model="formData.toTerminateCity"
                />
              </div>
              <!-- Kündigungsdaten ende-->
            </div>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="grid grid-nogutter justify-content-between">
          <Button
            :label="$t('back')"
            @click="prevPage()"
            icon="pi pi-angle-left"
          />
          <Button
            :label="$t('next')"
            @click="nextPage()"
            icon="pi pi-angle-right"
            iconPos="right"
          />
        </div>
      </template>
    </Card>
  </div>
</template>

<script setup>
import { getCurrentInstance, reactive,onMounted,onUpdated} from "vue";
import { useToast } from "primevue/usetoast";
import { useI18n } from "vue-i18n";
/* import { ValidationSchemaRegister2cg } from "./ValidationSchemaRegister2cg";
import { ValidationSchemaRegister2cgtt } from "./ValidationSchemaRegister2cgtt";
import { ValidationSchemaRegister2n } from "./ValidationSchemaRegister2n";
import { ValidationSchemaRegister2ntt } from "./ValidationSchemaRegister2ntt"; */
import { ValidationSchemaRegister2 } from "../validation/ValidationSchemaRegister2";
import {localStore} from "@/store/store";

const store = localStore();

const toast = useToast();
const { t } = useI18n();
const { emit } = getCurrentInstance();



//const formDataRegister2 = inject("formDataRegister2"); // zum befüllen der Werte aus Register2
//const formDataRegister = inject("formDataRegister"); // wegen abfrage Kündigen Plegedienst Pflegeperson
//const formDataQuestions = inject("formDataQuestions"); // wegen abfrage packstation
//const formDataRegister2 = reactive(store.formDataRegister2); // zum befüllen der Werte aus Register2
//const formDataRegister = reactive(store.formDataRegister); // wegen abfrage Kündigen Plegedienst Pflegeperson
const formDataQuestions = reactive(store.formDataQuestions); // wegen abfrage packstation

const formData = reactive({
  caregiverSalutation: "",
  caregiverFirstName: "",
  caregiverSurName: "",
  caregiverStreet: "",
  caregiverHouseNumber: "",
  caregiverPostCode: "",
  caregiverCity: "",
  caregiverPhone: "",
  caregiverRelationship: "",
  nursingServiceCompany: "",
  nursingServiceStreet: "",
  nursingServiceHouseNumber: "",
  nursingServicePostCode: "",
  nursingServiceCity: "",
  nursingServicePhone: "",
  toTerminateCompanyName: "",
  toTerminateStreet: "",
  toTerminateHouseNumber: "",
  toTerminatePostCode: "",
  toTerminateCity: "",
});
store.setFormDataRegister2(formData);

const getInvalidFields = () => {
  Object.assign(formData,formDataQuestions);
  const invalidFields = [];
  try {
    ValidationSchemaRegister2.validateSync(formData, {
      abortEarly: false,
    });
  } catch (error) {
    error.inner.forEach((fieldError) => {
      invalidFields.push([fieldError.path,fieldError.message]);
    });
  }
  return invalidFields;
};
const showError = () => {
  toast.removeAllGroups();
  const invalidFields = getInvalidFields();
  const errorMessages = invalidFields.map(([fieldName, message]) => {
    const translatedField = t("validation."+fieldName);
    const translatedMessage = t(message);
    return `${translatedField} ${translatedMessage}`;
  });
  const transe = t("pleaseFillField");
  errorMessages.forEach(errorMessage => {
    toast.add({
      severity: "error",
      summary: transe,
      detail: errorMessage,
      life: 4000,
    });
  });
};

/* const showError = () => {
  toast.removeAllGroups();
  const invalidFields = getInvalidFields();
  invalidFields.forEach((fieldName) => {
    let translatedField = t(fieldName);
    const transe = t("pleaseFillField");
    toast.add({
      severity: "error",
      summary: transe,
      detail: translatedField,
      life: 2000,
    });
  });
}; */

function nextPage() {
  if (getInvalidFields().length > 0) {
    showError();
  } else {
    const pageIndex = 3;
    emit("next-page", { pageIndex });
  }
}

const prevPage = () => {
  emit("prev-page", { pageIndex: 3 });
};

onUpdated(() => {
  toast.removeAllGroups();
  //store.setFormDataRegister2(formData);
});


onMounted(() => {
  window.scrollTo({
    top: 0,
    behavior: 'auto' // Scrollt sofort nach oben, ohne Animation
  });

});

onUpdated(() => {
//store.setFormDataRegister2(formData);
})
</script>
