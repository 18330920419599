<template>
  <div>
    <AutoComplete
      v-model="selectedInsurance"
      optionLabel="insuName"
      :suggestions="filteredInsurances"
      @complete="search"
    >
      <template #option="{ option }">
        <div>
          <div>{{ option.insuName }}</div>
        </div>
      </template>
    </AutoComplete>
  </div>
</template>

<script setup>
import { ref, onMounted,toRef } from "vue";
import InsuranceModel from "../Model/insurance-model";
import getAxiosDataWithModel from "../Model/axios-model-loader";

const selectedInsurance = ref(null);
const filteredInsurances = ref([]);
const insuranceDataRef = ref(null);
const Formdata = toRef(this, 'Formdata');

onMounted(async () => {
  try {
    const url = process.env.VUE_APP_BASE_API_URL_PATIENT + "/insurance-all";
    const insuranceData = await getAxiosDataWithModel(url, InsuranceModel);
    filteredInsurances.value = insuranceData.insuranceList;
    // Speichere die insuranceData in einer Ref-Variable, um später darauf zugreifen zu können
    insuranceDataRef.value = insuranceData;
  } catch (error) {
    console.error("Error fetching insurance data:", error);
  }
});

const search = (event) => {
  setTimeout(() => {
    if (!event.query.trim().length) {
      filteredInsurances.value = selectedInsurance.value?.insuranceList || insuranceDataRef.value?.insuranceList || [];
    } else {
      filteredInsurances.value = insuranceDataRef.value?.insuranceList?.filter((insurance) => {
        return insurance.insuName.toLowerCase().startsWith(event.query.toLowerCase());
      }) || [];
    }
  }, 250);
};


</script>
