<!--ServerAnfrage   -->
<template>
    <div>
      <div v-if="loading">Lädt...</div>
      <div v-else>
        <div v-if="error">Fehler: {{ error }}</div>
        <div v-else>
          <p>Patient UID: {{ patientUid }}</p>
          <button @click="sendData">Sende Data</button>
        </div>
      </div>
      <div v-if="httpStatus">HTTP Status Code: {{ httpStatus }}</div>
    </div>
  </template>
  
  <script setup>
  import { ref, onMounted } from 'vue';
  import iAxios from './axiosInterface';
  
  const patientUid = ref('');
  const loading = ref(false);
  const error = ref('');
  const httpStatus = ref(0);
  
  onMounted(async () => {
    loading.value = true;
  
    try {
      const response = await fetch(process.env.VUE_APP_BASE_API_URL_PATIENT+'/userid');
      httpStatus.value = response.status;
  
      if (response.ok) {
        const data = await response.json();
        patientUid.value = data.patientUid;
      } else {
        error.value = 'Fehler beim bekommen der UID';
      }
    } catch (err) {
      error.value = err.message;
    }
  
    loading.value = false;
  });
  
  const sendData = async () => {
    let url = process.env.VUE_APP_BASE_API_URL_PATIENT + "/create-user/";
    const data = {
      patientUid: patientUid.value,
      firstName: "erstername",
      surName: "nachname",
      companyId: "haza"
    };

    console.log(data);
    
    try {
      const response = await iAxios.post(url,data);


      console.log("RESPONSE_WHOAMI: ", response.data)
 
      if (response.status === 200) {
        console.log('Daten erfolgreich gesendet');
      } else {
        console.error('hab gefailt beim senden der Daten an Backend');
      }
    } catch (err) {
      console.error('Fehler beim senden der Daten olum:', err);
    }
  };
  </script>
  