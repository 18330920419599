/* slug.js */
class SlugComponent {
  constructor(router, api) {
    this.router = router;
    this.init();
  }

  init() {
    const slug = this.getSlug();
  }

  hasSlug() {
    return (
      this.router.currentRoute.value.params.companyId != null &&
      this.router.currentRoute.value.params.companyId !== ""
    );
  }

  getSlug() {
    return this.router.currentRoute.value.params.companyId;
  }
}

export default SlugComponent;
