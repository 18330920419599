import axios from "axios";

// definition of request <pre> settings and headers
// these will be applied for all requests made with defAxios
const iAxios = axios.create({
    headers: {
        "Content-Type": "application/json",
    },  
    baseURL: process.env.VUE_APP_BASE_API_URL,
    responseType: "json"
});
/* 
iAxios.interceptors.response.use((config) => {
    return config;
  });
 */
  export default iAxios;