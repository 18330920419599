// validationSchema.js
import * as yup from 'yup';

export const ValidationSchemaQuestion = yup.object({
  careLevel: yup.string().required(),
  whereToSendBox: yup.string().required(),
  insuranceKind: yup.string().required(),
  careType: yup.string().required(),
  qTerminateContract: yup.string().required(),
  packageDeliverSelection: yup.object().shape({
    value: yup.number().oneOf([1, 2, 3]).required(),
    label: yup.string().required(),
  }).required(),
});
