/*axios-model-loader.js*/
import iAxios from '../api/axiosInterface';

const debug=false; //TODO debug
const getAxiosDataWithModel = async (url, Model) => {
  try {
    if (debug)console.log("url:"+url);//TODO debug

    const response = await iAxios.get(url);
    const data = response.data;
    if (debug)console.log("Backenend-daten:"+JSON.stringify (data)); //TODO debug
    const model = new Model(data);
    model.fromJson(data);
    return model;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export default getAxiosDataWithModel;
