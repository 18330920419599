<template>

<Image src="https://i.ds.at/RuEbsw/rs:fill:750:0/plain/2023/04/08/Crack.Passwords.jpg" alt="Image" width="100" preview />

  <div>
    <div class="companydata" v-if="responseCompanyData.cname">
      <span v-if="responseCompanyData.showImpressum==true">
        <Button style="padding:0!important" @click="showImpressumDialog=true" size="small" label="Impressum" text />
      </span>
      {{ responseCompanyData.cname }}, {{ responseCompanyData.streetName }}
      {{ responseCompanyData.streetNumber }},
      {{ responseCompanyData.postCode }} {{ responseCompanyData.city }},
      {{ responseCompanyData.email }}, {{ responseCompanyData.phone }}
    </div>
    <div class="field col-12">
      <div class="video-container">
        <video
          playsinline
          autoplay
          muted
          loop
          poster="@/assets/seniorenpflege_sw_bo.jpg"
          id="bgvid"
        >
          <source src="@/assets/seniorenpflege_sw_bo.mp4" type="video/mp4" />
        </video>
      </div>
      <div class="welcome-container conti">
        <div>
          <div class="Josefin">Willkommen bei</div>
          <div class="cname">{{ responseCompanyData.cname }}</div>
        </div>

        <div class="field col-12 sm:col-12 md:col-9 lg:col-7">
          <p>
            {{ $t("welcomeText") }}
          </p>
        </div>

        <div v-if="!store.notReady" class="">
          <a href="#" @click="startStepper"
            ><span></span>{{ $t("startRequestNow") }}</a
          >
        </div>
        <div v-if="store.notReady" style="color: rgb(189, 109, 109)">
          Ihr Anbieter ist für diesen Dienst noch nicht freigeschaltet. Bitte
          wenden Sie sich an den support: per Email:
          {{ responseCompanyData.email }} oder Telefon:
          {{ responseCompanyData.phone }}
        </div>
        <Toast />
        <Dialog
                    v-model:visible="showImpressumDialog"
                    modal
                    header="Impressum"
                    :style="{ width: '50vw' }"
                    :breakpoints="{ '960px': '75vw', '641px': '100vw' }"
                  >
                  <div v-html="responseCompanyData.impressum"></div>
                  </Dialog> 
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref,reactive, onMounted, onUpdated, inject } from "vue";
import SlugComponent from "@/api/slug.js";
import { router } from "../router";
import iAxios from "../api/axiosInterface";
import { localStore } from "@/store/store";

const showImpressumDialog=ref(false);
const store = localStore();
const stepbar = inject("stepbar");

//const responseCompanyData = inject("companyData");
const responseCompanyData = store.companyData;

const slug = new SlugComponent(router, SlugComponent);
let slugValue = slug.getSlug();

let response = reactive({});

const getData = async () => {
  let url = process.env.VUE_APP_BASE_API_URL_PATIENT + "/whoami/" + slugValue;
  try {
    response = await iAxios.get(url);
    if (response.status === 200) {
      store.setNotReady(false);
      //console.log("🚀 ~ file: Welcome.vue:70 ~ getData ~ response:", response); //TODO debug
      cdata();
    } else {
      console.error("Fehler beim Senden der Daten an Backend");
    }
  } catch (err) {
    store.setNotReady(true);
    store.companyData = {};
    console.error("Fehler beim Senden der Daten:", err);
  }
};

function cdata() {
  if (response && slugValue) {
    console.log("🚀 ~ file: Welcome.vue:105 ~ cdata ~ response:", response)
    responseCompanyData.companyId = response.data.cid;
    responseCompanyData.anualItem = response.data.anualItem;
    responseCompanyData.slug = slugValue;
    responseCompanyData.cname = response.data.cname;
    responseCompanyData.companyStatus = response.data.status;
    responseCompanyData.productList = response.data.productList;
    responseCompanyData.cname = response.data.cname;
    responseCompanyData.city = response.data.city;
    responseCompanyData.email = response.data.email;
    responseCompanyData.phone = response.data.phone;
    responseCompanyData.postCode = response.data.postCode;
    responseCompanyData.streetName = response.data.streetName;
    responseCompanyData.streetNumber = response.data.streetNumber;
    responseCompanyData.agb =response.data.agb;
    responseCompanyData.widerruf=response.data.widerruf;
    responseCompanyData.datenschutz=response.data.datenschutz;
    responseCompanyData.impressum=response.data.impressum;
    responseCompanyData.showImpressum=response.data.showImpressum;
  }
}

onUpdated(() => {
  stepbar.value = false;
  cdata();
});

const startStepper = () => {
  stepbar.value = true;
  router.push({ name: "START" });
};

onMounted(() => {
  stepbar.value = false;
  getData();
});
</script>

<style scoped>
@media screen and (max-width: 768px) {
  .title {
    font-size: 50px;
  }
}

@media screen and (max-width: 480px) {
  .title {
    font-size: 40px;
  }
}

@media screen and (max-width: 320px) {
  .title {
    font-size: 30px;
  }
}

.video-container {
  position: relative;
  z-index: -10;
}

video {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: absolute;
  margin-top: -35px;
  margin-left: -15px;
}
.cname {
  color: rgba(255, 255, 255);
  font-family: "Playfair Display";
  font-size: 50px;
  margin-left: 10px;
  margin-top: -10px;
}

p {
  -webkit-font-smoothing: auto;
  -webkit-font-smoothing: auto;
  margin: 5px 0;
  font-size: 20px !important;
  color: #ffffff;
  font-weight: 300;
  font-family: "Josefin Sans", sans-serif;
  margin-bottom: 34px;
}

.lightgreen {
  color: #75e081 !important;
}

.Josefin {
  font-family: "Josefin Sans", sans-serif;
  line-height: 45px !important;
  margin-bottom: 10px !important;
  font-size: 60px;
  text-transform: uppercase;
  font-weight: 100;
  max-width: 650px;
  color: #75e081 !important;
}

.companydata {
  position: relative;
  width: auto;
  margin-top: -10px;
  margin-left: -5px;
  padding: 0;
  color: #ffffff;
  font-size: 16px;
}
.welcome-container.conti {
  position: absolute;
  bottom: 15%;
}

a {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0%);
  width: 360px;
  height: 30px;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  line-height: 30px;
  color: #6bc975;
  font-size: 30px;
  font-family: "Josefin Sans", sans-serif;
  font-weight: 700;
}

a:before,
a:after,
span:before,
span:after {
  content: "";
  position: absolute;
  width: 260px;
  height: 1px;
  background: #68c372;
  transition: 1.1s;
  mix-blend-mode: hue;
}

a:before {
  top: -10px;
  left: -2px;
}
a:after {
  top: -10px;
  right: -2px;
}
span:before {
  bottom: -30px;
  left: -2px;
}
span:after {
  bottom: -30px;
  right: -2px;
}

a:hover:before,
a:hover:after,
a:hover span:before,
a:hover span:after {
  width: calc(75px / 3);
  height: calc(120px / 2);
}

@media screen and (max-width: 768px) {
  .video-container {
    position: relative;
    z-index: -10;
  }

  video {
    object-fit: cover;
    width: 100vw;
    height: 92vh;
    position: absolute;
    margin-top: -16px;
    margin-left: -15px;
  }
  .cname {
    color: rgba(255, 255, 255);
    font-family: "Playfair Display";
    font-size: 50px;
    margin-left: 10px;
    margin-top: -10px;
  }

  p {
    -webkit-font-smoothing: auto;
    -webkit-font-smoothing: auto;
    margin: 5px 0;
    font-size: 18px !important;
    color: #ffffff;
    font-weight: 300;
    font-family: "Josefin Sans", sans-serif;
    margin-bottom: 20px;
  }

  .lightgreen {
    color: #75e081 !important;
  }

  .Josefin {
    font-family: "Josefin Sans", sans-serif;
    line-height: 45px !important;
    margin-bottom: 10px !important;
    font-size: 40px;
    text-transform: uppercase;
    font-weight: 100;
    max-width: 650px;
    color: #75e081 !important;
  }

  .companydata {
    position: absolute;
    width: auto;
    margin-top: -5px;
    margin-left: -5px;
    padding: 0;
    color: #ffffff;
    font-size: 14px;
  }
  .welcome-container.conti {
    position: absolute;
    bottom: 20%;
  }


  a {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0%);
    width: 300px;
    height: 30px;
    text-decoration: none;
    text-transform: uppercase;
    text-align: center;
    line-height: 30px;
    color: #6bc975;
    font-size: 18px;
    font-family: "Josefin Sans", sans-serif;
    font-weight: 700;
  }

  a:before,
  a:after,
  span:before,
  span:after {
    content: "";
    position: absolute;
    width: 220px;
    height: 1px;
    background: #68c372;
    transition: 1s;
    mix-blend-mode: hue;
  }

  a:before {
    top: -2px;
    left: -2px;
  }
  a:after {
    top: -2px;
    right: -2px;
  }
  span:before {
    bottom: -2px;
    left: -2px;
  }
  span:after {
    bottom: -2px;
    right: -2px;
  }

  a:hover:before,
  a:hover:after,
  a:hover span:before,
  a:hover span:after {
    width: calc(30px / 2);
    height: calc(50px / 2);
  }
}
</style>
