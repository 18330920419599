import { createI18n } from 'vue-i18n';
import en from './locales/en.json';
import de from './locales/de.json';
import tr from './locales/tr.json';

const i18n = createI18n({
  legacy: false,
  locale: 'de', // Fallback-Sprache als Standardsprache festlegen
  fallbackLocale: 'de',
  messages: {
    en,
    de,
    tr
  }
});

export default i18n;