<template>
  <div>
    <Toast />
    <div v-show="stepbar==true" class="card">
      <SelectButton
        v-model="langswitchValue"
        :options="options"
        aria-labelledby="basic"
        class="lang"
      />
      <Steps :model="items" aria-label="Form Steps" />
    </div>
    <router-view
      v-slot="{ Component }"
      :formData="formObject"
      @prevPage="prevPage($event)"
      @nextPage="nextPage($event)"
    >
      <keep-alive>
        <component :is="Component" />
      </keep-alive>
    </router-view>
  </div>
</template>

<script setup>
import { ref, provide, reactive, watch, inject } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";

let stepbar=inject("stepbar");


const { locale } = useI18n();
const langswitchValue = ref("Deutsch");
const options = ref(["Deutsch", "English", "Türkce"]);

watch(langswitchValue, (newValue) => {
  if (newValue === "Deutsch") {
    locale.value = "de";
  } else if (newValue === "English") {
    locale.value = "en";
  } else if (newValue === "Türkce") {
    locale.value = "tr";
  }
  stepbar=true;//demit es nicht nach lang wechsel evrschwindet
});


const items = [
  {
    label: "Packetauswahl",
    to: { name: "START" },
  },
  {
    label: "Start",
    to: { name: "QUESTIONS" },
  },
  {
    label: "Dateneingabe",
    to: { name: "REGISTER" },
  },
  {
    label: "weitere Daten",
    to: { name: "REGISTER2" },
  },
  {
    label: "Bestätigung",
    to: { name: "CONFIRMATION" },
  },
];

const formObject = ref({});

/* const formDataQuestions = reactive({});
const formDataRegister = reactive({});
const formDataRegister2 = reactive({});
const companyData = reactive({}); */
const boxValidate = reactive({});

/* provide("formDataQuestions", formDataQuestions);
provide("formDataRegister", formDataRegister);
provide("formDataRegister2", formDataRegister2);
provide("companyData", companyData); */
provide("boxValidate", boxValidate);

const route = useRoute();
const router = useRouter();

function nextPage(event) {
  for (let field in event.formData) {
    formObject.value[field] = event.formData[field];
  }

  router.push(items[event.pageIndex + 1].to);
}

function prevPage(event) {
  router.push(items[event.pageIndex - 1].to);
}

/* function complete() {
  const toastMessage = `Dear, ${formObject.value.firstName} ${formObject.value.surName} your order completed.`;
  $toast.add({
    severity: "success",
    summary: "Order submitted",
    detail: toastMessage,
  });
} */
</script>

<style scoped>
:deep(b) {
  display: block;
}

:deep(.p-card-body) {
  padding: 2rem;
}

.lang {
  display: flex;
  justify-content: flex-end;
  margin-top: -30px;
  margin-bottom: 10px;
  padding-right: 20px;
  height: 30px; /* Beispiel: Breite auf 150px setzen */
}
</style>
