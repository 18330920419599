<template>
  <div class="Confirm-step">
    <BlockUI :blocked="blocked" fullScreen />
    <Card>
      <template v-slot:title>{{ $t("stepConfirm") }}</template>
      <template v-slot:content>
        <div class="field col-12 md:col-12 mx-auto">
          <div class="p-fluid formgrid grid">
            <div class="p-col-12" v-show="!buttonDisabled">
              <div style="display: flex">
                <div style="flex: 1">
                  <img src="@/assets/danke.png" />
                </div>
                <div style="flex: 2">
                  <div>
                    {{ $t("thankYouForInquiry") }}
                    {{ $t("appreciateYourInterest") }}
                  </div>
                  <p>{{ $t("takeNoteOfPatientID") }}</p>
                  <p class="text-center">
                    <b>{{ PatienteniD4Show }}</b>
                  </p>
                  <div>{{ $t("downloadConfirmation") }}</div>

                  <Dialog
                    v-model:visible="agb"
                    modal
                    :header="$t('termsAndConditions')"
                    :style="{ width: '50vw' }"
                    :breakpoints="{ '960px': '75vw', '641px': '100vw' }"
                  >
                  <div v-html="companyData.agb"></div>

                  </Dialog>
                  <Dialog
                    v-model:visible="widerruf"
                    modal
                    :header="$t('cancellationPolicy')"
                    :style="{ width: '50vw' }"
                    :breakpoints="{ '960px': '75vw', '641px': '100vw' }"
                  >
                  <div v-html="companyData.widerruf"></div> 
                  </Dialog> 
                  <Dialog
                    v-model:visible="datenschutz"
                    modal
                    :header="$t('privacy_contract')"
                    :style="{ width: '50vw' }"
                    :breakpoints="{ '960px': '75vw', '641px': '100vw' }"
                  >
                  <div v-html="companyData.datenschutz"></div> 
                  </Dialog>
                  <div
                    v-show="
                      formDataQuestions.qTerminateContract == 'qTerminateYes'
                    "
                  >
                    <div>
                      <div>und auch das Kündigungsschreiben herunterladen.</div>
                      <Button
                        :label="$t('instructions')"
                        size="small"
                        icon="pi pi-external-link"
                        @click="manualVisible = true"
                      />
                      <Dialog
                        v-model:visible="manualVisible"
                        modal
                        :header="$t('instructions')"
                        :style="{ width: '80vw' }"
                        :breakpoints="{ '960px': '75vw', '641px': '100vw' }"
                      >
                        <div>
                          {{ $t("attachCancellationLetter") }}
                        </div>
                        <div>{{ $t("emailTextExample") }}</div>

                        <div style="border: 1px solid silver">
                          {{ $t("subjectCancellation") }}
                          <p>{{ $t("dearSirOrMadam") }}</p>
                          {{ $t("attachedIsCancellation") }}
                          <p>{{ $t("bestRegards") }}</p>
                          <p>
                            {{ formDataQuestions.firstName }}
                            {{ formDataQuestions.surName }}
                          </p>
                        </div>
                        <template #footer>
                          <Button
                            :label="$t('copyText')"
                            size="small"
                            small
                            icon="pi pi-check"
                            @click="copyText"
                            autofocus
                          />
                        </template>
                      </Dialog>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-show="buttonDisabled" class="p-fluid formgrid grid">
              <Fieldset :legend="$t('dataOfInsuredPerson')">
                <div>
                  {{
                    formDataRegister.salutation
                      ? formDataRegister.salutation
                      : ""
                  }}
                  {{
                    formDataRegister.firstName
                      ? formDataRegister.firstName
                      : ""
                  }}
                  {{
                    formDataRegister.surName ? formDataRegister.surName : "-"
                  }}
                </div>
                <div>
                  {{
                    formDataRegister.street
                      ? formDataRegister.street
                      : ""
                  }}
                  {{
                    formDataRegister.houseNumber
                      ? formDataRegister.houseNumber
                      : ""
                  }}
                </div>
                <div>
                  {{
                    formDataRegister.postCode
                      ? formDataRegister.postCode
                      : ""
                  }}
                  {{ formDataRegister.city ? formDataRegister.city : "" }}
                </div>
                <div>
                  geb.
                  {{
                    formDataRegister.birthDate
                      ? formatDate(formDataRegister.birthDate)
                      : ""
                  }}
                </div>
                <div>
                  {{
                    formDataRegister.emailAddress
                      ? formDataRegister.emailAddress
                      : ""
                  }}
                </div>
                <div>
                  Krankenversicherung:
                  <span
                    v-show="
                      formDataQuestions.insuranceKind === 'qLegalInsurance'
                    "
                  >
                    {{
                      formDataRegister.healthInsurance
                        ? formDataRegister.healthInsurance.insuName
                          ? formDataRegister.healthInsurance.insuName
                          : formDataRegister.healthInsurance
                        : ""
                    }}
                  </span>
                  <span
                    v-show="
                      formDataQuestions.insuranceKind != 'qLegalInsurance'
                    "
                  >
                    {{
                      formDataQuestions.insuranceKind === "qPrivatInsurance"
                        ? $t("qPrivatInsurance")
                        : formDataQuestions.insuranceKind ===
                          "qSocialWelfareOffice"
                        ? $t("qSocialWelfareOffice")
                        : formDataQuestions.insuranceKind === "qEligibility"
                        ? $t("qEligibility")
                        : ""
                    }}
                  </span>
                </div>
                <div>
                  <span
                    v-show="
                      formDataQuestions.insuranceKind === 'qLegalInsurance'
                    "
                  >
                    {{ $t("insurancePolicyNumber") }}
                    {{
                      formDataRegister.insurancePolicyNumber
                        ? formDataRegister.insurancePolicyNumber
                        : ""
                    }}
                  </span>
                </div>
              </Fieldset>
              <Fieldset
                v-show="formDataQuestions.whereToSendBox == 'qDeliverPackstation'"
                :legend="$t('packstation')"
              >
                <div>
                  <b>{{
                    formDataRegister.packstationPostNumber
                      ? "Packstation"
                      : "Packstation"
                  }}</b>
                </div>
                <div>
                  {{
                    formDataRegister.packstationPostNumber
                      ? $t('packstationPostNumber')+":" +
                        formDataRegister.packstationPostNumber
                      : $t('packstationPostNumber')
                  }}
                </div>
                <div>
                  {{
                    formDataRegister.packstationNumber
                      ? $t('packstationNumber')+": " +
                        formDataRegister.packstationNumber
                      : $t('packstationNumber')
                  }}
                </div>
                <div>
                  {{
                    formDataRegister.packstationPostCode
                      ? formDataRegister.packstationPostCode
                      : "$t('postCode')"
                  }}
                  {{
                    formDataRegister.packstationCity
                      ? formDataRegister.packstationCity
                      : "$t('city')"
                  }}
                </div>
              </Fieldset>
              <Fieldset
                v-show="formDataQuestions.careType == 'qCarePerson'"
                :legend="$t('caregiver')"
              >
                <div>
                  {{
                    formDataRegister2.caregiverSalutation
                      ? formDataRegister.caregiverSalutation
                      : ""
                  }}
                  {{
                    formDataRegister2.caregiverFirstName
                      ? formDataRegister2.caregiverFirstName
                      : ""
                  }}
                  {{
                    formDataRegister2.caregiverSurName
                      ? formDataRegister2.caregiverSurName
                      : ""
                  }}
                </div>
                <div>
                  {{
                    formDataRegister2.caregiverStreet
                      ? formDataRegister2.caregiverStreet
                      : ""
                  }}
                  {{
                    formDataRegister2.caregiverHouseNumber
                      ? formDataRegister2.caregiverHouseNumber
                      : ""
                  }}
                </div>
                <div>
                  {{
                    formDataRegister2.caregiverPostCode
                      ? formDataRegister2.caregiverPostCode
                      : ""
                  }}
                  {{
                    formDataRegister2.caregiverCity
                      ? formDataRegister2.caregiverCity
                      : ""
                  }}
                </div>
                <div>
                  {{
                    formDataRegister2.caregiverPhone
                      ? formDataRegister2.caregiverPhone
                      : ""
                  }}
                </div>
                <div>
                  {{
                    formDataRegister2.caregiverRelationship
                      ? formDataRegister2.caregiverRelationship
                      : ""
                  }}
                </div>
              </Fieldset>
              <Fieldset
                v-show="formDataQuestions.careType != 'qCarePerson'"
                :legend="$t('careService')"
              >
                <div>
                  <div>
                    {{
                      formDataRegister2.nursingServiceCompany
                        ? formDataRegister2.nursingServiceCompany
                        : ""
                    }}
                  </div>
                  <div>
                    {{
                      formDataRegister2.nursingServiceStreet
                        ? formDataRegister2.nursingServiceStreet
                        : ""
                    }}
                    {{
                      formDataRegister2.nursingServiceHouseNumber
                        ? formDataRegister2.caregiverHounursingServiceHouseNumberseNumber
                        : ""
                    }}
                  </div>
                  <div>
                    {{
                      formDataRegister2.nursingServicePostCode
                        ? formDataRegister2.nursingServicePostCode
                        : ""
                    }}
                    {{
                      formDataRegister2.nursingServiceCity
                        ? formDataRegister2.nursingServiceCity
                        : ""
                    }}
                  </div>
                  <div>
                    {{
                      formDataRegister2.nursingServicePhone
                        ? formDataRegister2.nursingServicePhone
                        : ""
                    }}
                  </div>
                </div>
              </Fieldset>
              <Fieldset :legend="$t('careAidBox')">
                <div class="field col-12">
                  <div>
                    <b>{{ $t("deliveryCareAidTo") }}:</b>
                    {{
                    $t(formDataQuestions.whereToSendBox)
                    }}
                  </div>
                  <div>
                    <b>{{ $t("careLevel") }}: </b
                    >{{
                      formDataQuestions.careLevel
                        ? formDataQuestions.careLevel
                        : ""
                    }}
                  </div>
                </div>
                <div class="field col-12 md:col-8">
                  <div>
                    <b>{{ $t("contentsOfAidsBox") }}</b>
                  </div>
                  <div>
                    <ul>
                      <li v-for="item in items" :key="item.code">
                        {{ item.quantity }} x {{ item.name }} <template v-if="item.groupName">{{ item.groupName }}: {{ item.variantValue }}</template>
                      </li>
                    </ul>
                  </div>
                </div>
              </Fieldset>
              <Fieldset v-if="formDataQuestions.qSendAnualItems=='qSendAnualItemsYes'" :legend="$t('anualItem')">
                <div>
                {{store.getCompanyData.anualItem.anualQuantity}} x Stk.
                {{store.getCompanyData.anualItem.itemName}}
              </div>
              </Fieldset>
              <Fieldset
                v-show="formDataQuestions.qTerminateContract == 'qTerminateYes'"
                :legend="$t('companyToCancel')"
              >
                <div>
                  {{
                    formDataRegister2.toTerminateCompanyName
                      ? formDataRegister2.toTerminateCompanyName
                      : ""
                  }}
                </div>
                <div>
                  {{
                    formDataRegister2.toTerminateStreet
                      ? formDataRegister2.toTerminateStreet
                      : ""
                  }}
                  {{
                    formDataRegister2.toTerminateHouseNumber
                      ? formDataRegister2.toTerminateHouseNumber
                      : ""
                  }}
                </div>
                <div>
                  {{
                    formDataRegister2.toTerminatePostCode
                      ? formDataRegister2.toTerminatePostCode
                      : ""
                  }}
                  {{
                    formDataRegister2.toTerminateCity
                      ? formDataRegister2.toTerminateCity
                      : ""
                  }}
                </div>
              </Fieldset>
              <Fieldset
                :legend="$t('message')"
              >
                <div>

          <span class="p-float-label">
            <Textarea id="message" v-model="message" rows="4" />
            <label for="message">{{$t('message')}}</label>
        </span>                  
                </div>
              </Fieldset>
              <Dialog
                @show="handleDialogShow"
                @after-hide="handleDialogHide"
                v-model:visible="agbUswVisible"
                :modal="false"
                :closable="true"
                @keydown.esc.prevent=""
                modal
                header="Unterschreiben und Abschicken"
                :style="{ width: '50vw' }"
                :breakpoints="{ '960px': '75vw', '641px': '80vw' }"
              >
                <div class="field col-12">
                  <div class="p-inputgroup flex-1">
                    <span class="p-inputgroup-addon">
                      <Checkbox
                        id="chbx2"
                        v-model="agbCheck"
                        binary
                        aria-describedby
                      />
                    </span>
                    <Button
                      :label="$t('acceptedTerms')"
                      size="small"
                      @click="agb = true"
                      outlined
                    />
                  </div>
                </div>

                <div class="field col-12">
                  <div class="p-inputgroup flex-1">
                    <span class="p-inputgroup-addon">
                      <Checkbox
                      size="small"
                        id="chbx3"
                        v-model="widerrufCheck"
                        binary
                        aria-describedby
                      />
                    </span>
                    <Button
                      size="small"
                      :label="$t('acceptedRevocation')"
                      @click="widerruf = true"
                      outlined
                    />
                  </div>
                </div>

                <div class="field col-12">
                  <div class="p-inputgroup flex-1">
                    <span class="p-inputgroup-addon">
                      <Checkbox
                        id="chbx1"
                        v-model="datenschutzCheck"
                        binary
                        aria-describedby
                        link
                      />
                    </span>
                    <Button
                      :label="$t('acceptedPrivacy')"
                      @click="datenschutz = true"
                      outlined=""
                    />
                  </div>
                </div>

                <div class="field col-12">
                  <Button
                    :label="$t('next')"
                    @click="validateAndShowSign"
                  ></Button>
                </div>

              </Dialog>
              <Dialog
                v-model:visible="signVisible"
                :modal="false"
                :closable="false"
                @keydown.esc.prevent=""
                modal
                :header="$t('signAndSend')"
                :style="{ width: '50vw' }"
                :breakpoints="{ '960px': '75vw', '641px': '100vw' }"
                @show="handleDialogShow"
                @after-hide="handleDialogHide"
              >
                <div
                  class="flex flex-column align-items-center"
                  style="flex: 1"
                >
                  <div class="text-center">
                    <i></i>
                    <div class="font-bold text-xl my-2"></div>
                  </div>
                  <div class="flex gap-1">
                    <div class="">
                      <Button
                        @click="abort"
                        :label="$t('abort')"
                        severity="danger"
                        icon="pi pi-times"
                      />
                      <Button
                        @click="clear"
                        :label="$t('delete')"
                        severity="warning"
                        icon="pi pi-delete-left"
                      />
                      <Button
                        icon="pi pi-check"
                        @click="save"
                        :label="$t('send')"
                        class="p-button-success"
                      />
                      <div>
                        <VueSignaturePad
                          id="signature"
                          width="100%"
                          height="200px"
                          ref="signaturePad"
                          :options="options"
                        />
                      </div>
                      <div class="line-1"></div>
                    </div>
                  </div>
                </div>
              </Dialog>

              <Toast />
              <ConfirmDialog> </ConfirmDialog>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="grid grid-nogutter justify-content-between">
          <Button
            :label="$t('back')"
            @click="prevPage()"
            icon="pi pi-angle-left"
            v-show="buttonDisabled"
          />
          <Button
            :label="$t('close')"
            @click="
              stepbar = false;
              router.push({ name: 'HOME' }, slug);
            "
            icon="pi pi-angle-left"
            v-show="!buttonDisabled"
          />
          <Button
            :label="$t('onlineSign')"
            icon="pi pi-external-link"
            severity="success"
            @click="
              agbUswVisible = true;
              blocked = true;
            "
            :disabled="!buttonDisabled"
          />
          <Button
            size="small"
            type="button"
            :label="$t('downloadConfirmation')"
            icon="pi pi-download"
            severity="help"
            :loading="loadingConfirm"
            @click="downloadConfirm"
            :disabled="buttonDisabled"
          />
          <Button v-if="formDataQuestions.qTerminateContract=='qTerminateYes'"
            size="small"
            type="button"
            :label="$t('downloadTermination')"
            icon="pi pi-download"
            severity="help"
            :loading="loadingTerminate"
            @click="downloadTerminate"
            :disabled="buttonDisabled"
          />
        </div>
      </template>
    </Card>
  </div>
</template>

<script setup>
import {
  getCurrentInstance,
  inject,
  ref,
  reactive,
  onMounted,
  onUpdated,
  watch,
} from "vue";
import { useI18n } from "vue-i18n";
import { useToast } from "primevue/usetoast";
import axios from "axios";
import { useConfirm } from "primevue/useconfirm";
import { router } from "../router";
import { Clipboard } from "v-clipboard";
import download from 'downloadjs'

import {localStore} from "@/store/store";
import InputText from "primevue/inputtext";

const store = localStore();
const blocked = ref(false);
const loadingTerminate  = ref(false);
const loadingConfirm = ref(false);
const buttonDisabled = ref(true);
const signVisible = ref(false);
const agbUswVisible = ref(false);
const manualVisible = ref(false);
const agbCheck = ref(false);
const datenschutzCheck = ref(false);
const widerrufCheck = ref(false);
const agb = ref(false);
const datenschutz = ref(false);
const widerruf = ref(false);
const message=ref("");
const items = ref([]);

const { t } = useI18n();
const { emit } = getCurrentInstance();

let signatureData = undefined;
const PatienteniD4Show = ref("");

const formDataRegister2 = reactive(store.formDataRegister2);
const formDataRegister = reactive(store.formDataRegister);
const formDataQuestions = reactive(store.formDataQuestions);
const companyData = reactive(store.companyData);
let patientUid="";

/* const formDataRegister2 = inject("formDataRegister2");
const formDataRegister = inject("formDataRegister");
const formDataQuestions = inject("formDataQuestions"); */
const stepbar = inject("stepbar");
stepbar.value = true;

const manualText = ref(
  t("subjectCancellation") +
    "\n" +
    t("dearSirOrMadam") +
    "\n" +
    t("attachedIsCancellation") +
    "\n" +
    t("bestRegards") +
    "\n" +
    formDataQuestions.firstName +
    " " +
    formDataQuestions.surName
);

const copyText = () => {
  Clipboard.copy(manualText.value)
    .then(() => {
      toast.add({
        severity: "info",
        summary: t("textSuccessfullyCopied"),
        detail: "",
        group: "bc",
      });
    })
    .catch(() => {
      toast.add({
        severity: "error",
        summary: t("copyFailed"),
        detail: t("copyYourSelf"),
        group: "bc",
      });
    });
  manualVisible.value = false;
};

const formatDate = (value) => {
  const date = new Date(value);
  return date.toLocaleDateString("de-DE", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
};

watch(agbUswVisible, () => {
  if (agbUswVisible) blocked.value = true;
  if (signVisible) blocked.value = true;
});

const handleDialogHide = () => {
  blocked.value = signVisible.value;
};

const handleDialogShow = () => {
  blocked.value = true;
};

const prevPage = () => {
  emit("prev-page", { pageIndex: 4 });
};

const validateAndShowSign = () => {
  if (agbCheck.value && datenschutzCheck.value && widerrufCheck.value == true) {
    agbUswVisible.value = false;
    signVisible.value = true;
    blocked.value = false;
  } else {
    const transe = t("error");
    const transeDetail = t("needAcceptTerms");
    toast.add({
      severity: "error",
      summary: transe,
      detail: transeDetail,
      life: 2000,
    });
  }
};


const downloadConfirm = async () => {
  loadingConfirm.value = true;
  
  await axios
        .get(
          "https://stagingapi.sanitybox.de/api/patient/antrag-kk/"+companyData.companyId+"/"+patientUid,
          {
            headers: {
              "Content-Type": "application/octet-stream",
            },
            responseType: 'blob'
          }
        )
        .then((response) => {
          const content = response.headers['content-type'];
          download(response.data, 'antrag_kk.pdf', content);
        })
        .catch((error) => {
          console.error(error);
        });
        loadingConfirm.value = false;
};

const downloadTerminate = async () => {
  loadingTerminate.value = true;
  
  await axios
        .get(
          "https://stagingapi.sanitybox.de/api/patient/terminate-contract/"+companyData.companyId+"/"+patientUid,
          {
            headers: {
              "Content-Type": "application/octet-stream",
            },
            responseType: 'blob'
          }
        )
        .then((response) => {
          const content = response.headers['content-type'];
          download(response.data, 'Kuendigung_'+formDataRegister.firstName+'_'+formDataRegister.surName+'.pdf', content);
        })
        .catch((error) => {
          console.error(error);
        });
        loadingTerminate.value = false;
};




const slug = "/" + companyData.slug;

onMounted(() => {
  window.scrollTo({
    top: 0,
    behavior: "auto",
  });

  if (store.warenkorb.boxtype === "predefined") {
    store.warenkorb.selectedBox.productItemList.forEach(prodItem => {
      if (prodItem.hasGroup) {
        items.value.push({ quantity: prodItem.quantity, name: prodItem.itemName, groupName: prodItem.itemGroupList[0].groupName, variantValue: prodItem.itemGroupList[0].selectedVariant.variantValue });
      } else {
        items.value.push({ quantity: prodItem.quantity, name: prodItem.itemName });
      }
    });
  } else if (store.warenkorb.boxtype === "custom") {
    store.warenkorb.selectedSanityItems.forEach(prodItem => {
      if (prodItem.hasGroup) {
        items.value.push({ quantity: prodItem.quantity, name: prodItem.itemName, groupName: prodItem.itemGroupList[0].groupName, variantValue: prodItem.itemGroupList[0].selectedVariant.variantValue });
      } else {
        items.value.push({ quantity: prodItem.quantity, name: prodItem.itemName });
      }
    });
  }  
});

const getWarenkorb4DB = () => {
  let warenkorb = [];

  if (store.warenkorb.boxtype === "predefined") {
    store.warenkorb.selectedBox.productItemList.forEach(prodItem => {
      let warenKorbEntry = {};
      warenKorbEntry.item_id = prodItem.item_id;
      warenKorbEntry.quantity = prodItem.quantity;
      if (prodItem.hasGroup) {
        warenKorbEntry.group_id= prodItem.itemGroupList[0].group_id;
        warenKorbEntry.variant_id = prodItem.itemGroupList[0].selectedVariant.variant_id;
      }
      warenkorb.push(warenKorbEntry);
    });
  } else if (store.warenkorb.boxtype === "custom") {
    store.warenkorb.selectedSanityItems.forEach(prodItem => {
      let warenKorbEntry = {};
      warenKorbEntry.item_id = prodItem.item_id;
      warenKorbEntry.quantity = prodItem.quantity;
      if (prodItem.hasGroup) {
        warenKorbEntry.group_id= prodItem.itemGroupList[0].group_id;
        warenKorbEntry.variant_id = prodItem.itemGroupList[0].selectedVariant.variant_id;
      }
      warenkorb.push(warenKorbEntry);
    });
  }  

  return warenkorb;
}

onUpdated(() => {
  /* platzhalter */
});
const confirm = useConfirm();

const toast = useToast();
const options = ref({
  penColor: "#00f",
});

const signaturePad = ref(null);

const toDB = () => {
  const data4Db = reactive({});
  Object.assign(data4Db, formDataRegister, formDataRegister2);
  data4Db.companyId = companyData.companyId;
  data4Db.cname = companyData.cname;
  data4Db.slug = companyData.slug;
  //Bereich StepperQuestions(SQ)
  data4Db.careLevel = formDataQuestions.careLevel;
  data4Db.whereToSendBox = formDataQuestions.whereToSendBox;
  data4Db.insuranceKind = formDataQuestions.insuranceKind;
  data4Db.careType = formDataQuestions.careType;
  data4Db.isTerminateOldContract = (formDataQuestions.qTerminateContract=='qTerminateYes');
  data4Db.anualItem= (formDataQuestions.qSendAnualItems=='qSendAnualItemsYes'?companyData.anualItem:null);
  console.log("🚀 ~ file: StepperConfirm.vue:890 ~ toDB ~ formDataQuestions:", formDataQuestions)
  //Ende SQ
  data4Db.signature = signatureData;
  data4Db.signedFlag = true;
  data4Db.message=message.value;
  data4Db.packageDeliverTime=formDataQuestions.packageDeliverSelection.value;
  (data4Db.insuranceName = formDataRegister.healthInsurance.insuName),
    (data4Db.insuranceIdNum = formDataRegister.healthInsurance.insuIdNum),
    delete data4Db.confirmEmail;
  delete data4Db.healthInsurance;

  //data4Db.patbox_setup = getWarenkorb4DB();
  data4Db.patbox_setup = store.warenkorb;
  console.log("🚀 ~ file: StepperConfirm.vue:901 ~ toDB ~ data4Db:", data4Db)
  return data4Db;
};

const clear = () => {
  console.log("toDB:", toDB()); //TODO DEBUG
  if (signaturePad.value) {
    signaturePad.value.clearSignature();
  }
};

const abort = () => {
  if (signaturePad.value) {
    const { isEmpty } = signaturePad.value.saveSignature();

    if (isEmpty) {
      signVisible.value = false;
      buttonDisabled.value = true;
      blocked.value = false;
    } else {
      if (!isEmpty) {
        const transeMessage = t("signedButStillCancel");
        const transeHeader = t("youHaveSigned");
        const transeAcceptLabel = t("yes");
        const transeRejectLabel = t("no");
        confirm.require({
          message: transeMessage,
          header: transeHeader,
          icon: "pi pi-exclamation-triangle",
          acceptClass: "p-button-danger",
          acceptLabel: transeAcceptLabel,
          rejectLabel: transeRejectLabel,
          accept: () => {
            signVisible.value = false;
            buttonDisabled.value = true;
            const transeSummary = t("abort");
            const transeDetail = t("notSigned");
            toast.add({
              severity: "error",
              summary: transeSummary,
              detail: transeDetail,
              life: 3000,
            });
            blocked.value = false;
          },
        });
      }
    }
  }
};


const save = async () => {
  if (signaturePad.value) {
    const { isEmpty, data } = signaturePad.value.saveSignature();
    signatureData = data;
    if (!isEmpty) {
      blocked.value = false;
      let responseData = null;
      await axios
        .post(
          "https://stagingapi.sanitybox.de/api/patient/create-user",
          toDB(),
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          console.log("🚀 ~ file: StepperConfirm.vue:1115 ~ .then ~ response:", response)
          responseData = response.data;
          patientUid=responseData.patientUid;
          console.log("🚀 ~ file: StepperConfirm.vue:1116 ~ .then ~ responseData:", responseData)
          
          PatienteniD4Show.value = responseData.patientId;
        })
        .catch((error) => {
          console.error(error);
        });

      const formData = new FormData();
      formData.append("sign_file", signatureData);
      formData.append("patientUid", responseData.patientUid);
      axios
        .post(
          "https://stagingapi.sanitybox.de/api/patient/upload-signature",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .catch((error) => {
          console.error(error);
        });

      signVisible.value = false;
      buttonDisabled.value = false;
    }
  }
};
</script>

<style>
#signature {
  border: double 3px transparent;
  border-radius: 5px;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #000000, #000000);
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.container {
  width: "100%";
  padding: 0px;
}

.line-1 {
  margin-top: -30px;
  height: 0px;
  border-style: dashed;
  size: 10px;
  border-color: #808080;
  width: 196%;
  margin-left: 10px;
  margin-bottom: 20px;
}

.line-1::before {
  content: "Unterschrift / Signature / Imza ";
  font-size: smaller;
  color: #808080;
  position: relative;
  top: 5px;
  left: 0px;
}

</style>
