import * as yup from 'yup';
import isEmailValidator from 'validator/lib/isEmail';

export const ValidationSchemaRegister = yup.object({

  firstName: yup.string().required('validation.isARequiredField'),

  surName: yup.string().required('validation.isARequiredField'),

  street: yup.string().required('validation.isARequiredField'),

  houseNumber: yup.string().required('validation.isARequiredField'),

  postCode: yup
    .string()
    .min(5, 'validation.isTooShort')
    .max(5, 'validation.IsTooLong')
    .matches(/^\d+$/, 'validation.invalidCharacters')
    .required('validation.isARequiredField'),

  city: yup.string().required('validation.isARequiredField'),

  birthDate: yup.string().required('validation.isARequiredField'),

  insuranceName: yup
    .string()
    .when('insuranceKind', {
      is: 'qLegalInsurance',
      then: () => yup
        .string()
        .min(3, 'validation.isTooShort')
        .required('validation.isARequiredField')
    }),

  insurancePolicyNumber: yup
    .string()
    .when('insuranceKind', {
      is: 'qLegalInsurance',
      then: () => yup.string()
        .matches(/^[A-Za-z]\d{9}$/, 'validation.invalidInput')
        .required('validation.isARequiredField'),
    }),

  emailAddress: yup
    .string()
    .required('validation.isARequiredField')
    .email('validation.invalidInput')
    .test(
      "is-valid",
      "validation.invalidInput",
      (value) => (value ? isEmailValidator(value) : true)
    ),

  confirmEmail: yup
    .string()
    .required('validation.isARequiredField')
    .trim()
    .oneOf([yup.ref('emailAddress'), null], 'validation.notSame'),


    packstationPostNumber: yup
    .string()
    .when('whereToSendBox', {
      is: 'qDeliverPackstation',
      then: () => yup
        .string()
        .min(6, 'validation.isTooShort')
        .max(10, 'validation.IsTooLong')
        .matches(/^\d+$/, 'validation.invalidCharacters')
        .required('validation.isARequiredField')
    }),
    
    packstationNumber: yup
    .string()
    .when('whereToSendBox', {
      is: 'qDeliverPackstation',
      then: () => yup
        .string()
        .min(3, 'validation.isTooShort')
        .max(3, 'validation.isTooLong')
        .matches(/^\d+$/, 'validation.invalidCharacters')
        .required('validation.isARequiredField')
    }),
    
    packstationPostCode: yup
    .string()
    .when('whereToSendBox', {
      is: 'qDeliverPackstation',
      then: () => yup
        .string()
        .min(5, 'validation.isTooShort')
        .max(5, 'validation.IsTooLong')
        .matches(/^\d+$/, 'validation.invalidCharacters')
        .required('validation.isARequiredField')
    }),
    
    packstationCity: yup
    .string()
    .when('whereToSendBox', {
      is: 'qDeliverPackstation',
      then: () => yup
        .string()
        .required('validation.isARequiredField')
    }),
    
});
